import { MsalAuthProvider, LoginType } from 'react-aad-msal';

/* Msal Configurations */
const config = {
  auth: {
    authority: 'https://login.microsoftonline.com/01cd50dc-51ef-4ef4-a647-7c281c1fec6f/',
    clientId: 'a87b7386-46d5-4848-91ba-d81f91ca9e61',
    // redirectUri: 'http://localhost:3000/auth',
    redirectUri: window.location.origin + '/auth',
    //postLogoutRedirectUri: window.location.origin,
  },
  cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true
    }
};

/* Authentication Parameters */
const authenticationParameters = {
  scopes: [
    'User.Read'
  ]
}

/* Options */
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin + '/auth'
}

export const authAADProvider = new MsalAuthProvider(config, authenticationParameters, options);