import React, { useEffect, useCallback, useRef, cloneElement } from 'react';
import axios from "axios";
import { Field } from 'react-final-form';
import { Link } from 'react-router-dom';
import { FormWithRedirect,
         List, Edit, Create, Pagination, Datagrid, downloadCSV, 
         TextField, DateField, ImageField,
         TextInput, DateInput, SelectInput,
         Button, CreateButton, SaveButton, EditButton, DeleteButton, ExportButton,
         Filter, 
         useNotify, required } from 'react-admin';

import { useQueryWithStore, Loading, Error } from 'react-admin';

import jsonExport from 'jsonexport/dist';

import { Box, makeStyles } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import EditIcon from '@material-ui/icons/Edit';

import  SliderTimeSlot from './components/FormElement/MUISliderTimeSlot.js';
import  Table from './components/FormElement/MUITable.js';
import  SearchBar from './components/FormElement/MUISearchBar.js';

import config from 'config';
import useAppData from './hooks/useAppData';

const useStyles = makeStyles({
    /*
    headerCell:{
        '&:last-child': {
            maxWidth: 16,
        }
    },
    rowCell:{
        '&:last-child': {
            maxWidth: 16,
        }
    },
    */
    /*
    rowEven: {
        backgroundColor: '#ffffff',
    },
    rowOdd: {
        backgroundColor: '#d9e1f2',
    },
    */
    divSignaturePad: {
        backgroundColor: '#fff',
    }
});

/* set row background color based on deal_status */
const TimeSheetRowStyle = (record, index) => {
    /*
    let backgroundColor = '#252425';
    if(record.status == 0) backgroundColor = "#6F6A6F";
    else if(record.status == 1) backgroundColor = "#f44a37"
    else if(record.status == 2) backgroundColor = "#5bd952";
    else if(index%2) backgroundColor = '#d9e1f2';
    return { backgroundColor: backgroundColor, color: 'red'};
    */
}

const FILTER_STATUS = config.timesheet.FILTER_STATUS;

const requiredValidate = require => value => {
    if(!require) return undefined;
    return ((value !== undefined && value !== null && value !== "") || typeof value === 'number' ? undefined : '* Required')
}

/* customize exporter */
const exporter = timesheets => {
    const timesheetForExport = timesheets.map(timesheet => {
        /* ignore fields */
        const { id, staff_id, start_slot, end_slot, status, status_lable, ...timesheetForExport } = timesheet;
        /* can add a field
        timesheetForExport.field = timesheet.field;
        */
        return timesheetForExport;
    });
    jsonExport(timesheetForExport, {
        /* order fields in the export */
        headers: ['staff_name', 'staff_nric', 'centre_uid', 'date', 'start', 'end', 'break'], 
        rename:["Staff name", "Staff NRIC", "Centre", "Date", "Start", "End", "Break",]
    }, (err, csv) => {
        downloadCSV(csv, 'timesheets'); // download as 'staffs.csv` file
    });
};

const TimeSheetPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

/* remove the export action in an list view */
const TimeSheetActions = ({
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    permanentFilter,
    hasCreate,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
    /* NOTE: If we use react-admin Toolbar will cause resource is null when pass to provider.
     * Then export function will be crashed
    */
    <Toolbar>
        {filters && cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        {hasCreate && <CreateButton basePath={basePath} />}
        {exporter !== false && (
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filter={{ ...filterValues, ...permanentFilter }}
                exporter={exporter}
            />
        )}
        {/* Add your custom actions
        <UIButton color="primary" onClick={null}>Custom</UIButton>
        */}
    </Toolbar>
);

/* Remove the <DeleteButton> from the toolbar in an edit view */
const TimeSheetEditToolbar = props => {
    return (
        <Toolbar {...props} >
            <SaveButton {...props} />
        </Toolbar>
    )
};

/* take a look at a component documentation and source code to know which classes are available for styling.
 * https://marmelab.com/react-admin/Theming.html
 * https://material-ui.com/customization/components/#overriding-styles-with-classes
*/
const useFilterStyles = makeStyles({
    job_role: {
         minWidth: '160px',
    },
});

const TimeSheetFilter = props => {
    const classes = useFilterStyles();
    return (
        <Filter classes={classes} {...props}>
            <TextInput label="Staff name" source="staff_name" alwaysOn />
            {/*
            <SelectInput 
                label="Status"
                source="status"
                choices={FILTER_STATUS}
                //allowEmpty emptyValue={"None"}
                alwaysOn
            />
            */}
            <DateInput 
                label="From"
                source="created_from" 
                alwaysOn
            />
            <DateInput
                label="To"
                source="created_to"
                alwaysOn
            />
        </Filter>
    );
}

/* replace default EditButton component to add more params */
const EditTimeSheetButton = ({ record }) => {
    /* resolve TypeError: record is undefined when click on delete
     * in case delete record
    */
    if(!record) return null;

    return(
        <Button
            component={Link}
            to={{
                pathname: `/timesheet/${record.id}`,
                /* pass centre_email to TimeSheetEdit (location.centre_email)
                 * then save to local storage and use in httpClient later
                */
                /*centre_email: record.centre_email,*/
            }} 
            /*onClick={(e)=>{window.location.href = activityURL}}*/
            title="Edit"
        >
            <EditIcon />
        </Button>
    );
}

/* Replace default DeleteButton component to control show/hide */
const DeleteTimeSheetButton = (props) => {
    const {data} = useAppData();
    if( props.record && props.record.user_name != "app" &&
        data.activity && data.activity.user && data.activity.user.includes("delete"))
        return <DeleteButton {...props}/>;
    return null;
}

/* LIST COMPONENT */
export const TimeSheetList = props => {
    const classes = useStyles();
    return (
        <List {...props} 
            exporter={exporter}
            pagination={<TimeSheetPagination />}
            perPage={25}
            actions={<TimeSheetActions />} 
            filters={<TimeSheetFilter />} 
            sort={{ field: 'status', order: 'DESC' }}
            /*
            filterDefaultValues={{ 
                status: "none", 
                job_role: "none" 
            }}
            */
            title="TimeSheets" 
            bulkActionButtons={false} 
        >
            <Datagrid 
                optimized 
                rowStyle={TimeSheetRowStyle}
                classes={{
                    headerCell: classes.headerCell,
                    rowCell: classes.rowCell
                    /*
                    rowEven: classes.rowEven,
                    rowOdd: classes.rowOdd
                    */
                }}
            >
                <TextField source="staff_name" />
                <TextField source="staff_nric" />
                <TextField source="centre_uid" label="Centre"/>
                <DateField 
                    source="date"
                    label="Date"
                    locales="sg-SG"
                    options={{ day: '2-digit', month: '2-digit', year: 'numeric' }} 
                    showTime={false} />
                <TextField source="start_slot" label="Start" />
                <TextField source="end_slot" label="End" />
                <TextField source="break" />
                {/*<StatusFieldTimeSheet source="status_label" />*/}
                {/*<EditButton />*/}
                <EditTimeSheetButton />
                <DeleteTimeSheetButton />
            </Datagrid>
        </List>
    )
};

/* EDIT COMPONENT */
export const TimeSheetEdit = props => {
    const classes = useStyles();
    const notify = useNotify();

    /* get centre list */
    const { loaded, error, data: centres } = useQueryWithStore({
        type: 'GET_LIST',
        resource: 'Centre',
    });

    if (!loaded) { return <Loading />; }
    if (error) { return <Error />; }
    if(!centres) return null;

    const handleSubmit = async (handleSubmitWithRedirect, record)=> {
        let error = false;
        try{
        }
        catch(err){
            error = true;
            notify(`Could not amend: ${err}`, 'error');
        }
        finally{
            if(!error)
                handleSubmitWithRedirect();
        }
    }

    /* transform call after handleSubmit */
    const transform = data => data

    const TimeSheetEditForm = props => {
        let START_RANGE = config.timesheet.START_RANGE,
            END_RANGE = config.timesheet.END_RANGE,
            BREAK_RANGE = config.timesheet.BREAK_RANGE;

        return (
        <FormWithRedirect
            {...props}
            render={formProps => (
                /* here starts the custom form layout */
                <form>
                    {/* width="50%" set layout 50% width only, can adjust this one */}
                    <Box p="1em" width="97%">
                        <Box display="flex" ml="0.5em" mb = "1em">
                            <span class="microbox-badge">{props.record.staff_name}</span>
                        </Box>
                        <Box display="flex" ml="0.5em" mb = "1em">
                            <Field required
                                validate={requiredValidate(true)}
                                label="Select centre"
                                name="centre_uid"
                                component={SelectInput}
                                optionValue="uid"
                                choices={centres}
                            />
                        </Box>
                        <Box display="flex">
                            <Box >
                                {/*<TextInput source="date" fullWidth/>*/}
                                <DateInput validate={[required()]}
                                    label="Date"
                                    source="date" 
                                    alwaysOn
                                />
                            </Box>
                            <Box flex={1} ml="0.5em">
                                {/*<TextInput source="start" fullWidth/>*/}
                                <Field  validate={requiredValidate(true)}
                                    label={"Start"}
                                    name={"start"}
                                    component={SliderTimeSlot}
                                    min={START_RANGE.min}
                                    max={START_RANGE.max}
                                    step={START_RANGE.step}
                                    val={props.record.start}
                                />
                            </Box>
                            <Box flex={1} ml="1em">
                                {/*<TextInput source="end" fullWidth/>*/}
                                <Field  required validate={requiredValidate(true)}
                                    label={"End"}
                                    name={"end"}
                                    component={SliderTimeSlot}
                                    min={END_RANGE.min}
                                    max={END_RANGE.max}
                                    step={END_RANGE.step}
                                    val={props.record.end}
                                />
                            </Box>
                            <Box flex={1} /*style={{flexGrow:1, flexShrink:1, flexBasis: 'auto'}}*/ ml="1em">
                                {/*<TextInput source="break" fullWidth/>*/}
                                <Field  required validate={requiredValidate(true)}
                                    label={"Break"}
                                    name={"break"}
                                    component={SliderTimeSlot}
                                    min={BREAK_RANGE.min}
                                    max={BREAK_RANGE.max}
                                    step={BREAK_RANGE.step}
                                    val={props.record.break}
                                />
                            </Box>
                        </Box>
                        <Box display="flex" ml="0.5em" mb = "1em">
                            <ImageField
                               source={props.record.signature} title="signature"
                            />
                        </Box>
                        {props.record.signature && <Box display="flex" ml="0.5em" mb = "1em">
                            <div className={classes.divSignaturePad}>
                                <img src={props.record.signature} title="signature" />
                            </div>
                        </Box>
                        }
                    </Box>
                    <Toolbar>
                        <Box display="flex" justifyContent="space-between" width="100%">
                            <SaveButton
                                saving={formProps.saving}
                                handleSubmitWithRedirect={
                                    /*formProps.handleSubmitWithRedirect*/
                                    () => handleSubmit(formProps.handleSubmitWithRedirect, formProps.record)
                                }
                                transform={transform}
                            />
                            {/*<DeleteButton record={formProps.record} />*/}
                        </Box>
                    </Toolbar>
                </form>
            )}
        />
        );
    }

    return (
        <Edit {...props} undoable={false}
        >
            <TimeSheetEditForm />
        </Edit>
    );
}

/* CREATE COMPONENT */
export const TimeSheetCreate = props => {
    const notify = useNotify();

    let status = useRef(null),
        arr_staff_searched = useRef(null),
        staff_selected =  useRef(null);

    let dispatchEventTable = null;

    /* get centre list */
    const { loaded, error, data: centres } = useQueryWithStore({
        type: 'GET_LIST',
        resource: 'Centre',
    });

    if (!loaded) { return <Loading />; }
    if (error) { return <Error />; }
    if(!centres) return null;

    const handleSubmit = async (handleSubmitWithRedirect, record)=> {
        let error = false;
        try{
            if(!staff_selected.current) throw "Error: please select staff first."
        }
        catch(err){
            error = true;
            notify(`${err}`, 'error');
        }
        finally{
            if(!error)
                handleSubmitWithRedirect();
        }
    }

    /* transform call after handleSubmit */
    const transform = (data) => {
        /* add additional data */
        data.staff_id = arr_staff_searched.current[staff_selected.current[0]].id;
        /* centre_uid will be from dropdown list
        data.centre_uid = arr_staff_searched.current[staff_selected.current[0]].centre_uid;
        */
        data.status = 2;
        /* back-end need to check permssion bases on path_uid */
        data.path_uid = data.centre_uid;
        return data;
    }

    const TimeSheetCreateForm = props => {
        let START_RANGE = config.timesheet.START_RANGE,
            END_RANGE = config.timesheet.END_RANGE,
            BREAK_RANGE = config.timesheet.BREAK_RANGE;

        const searchStaffs = useCallback(async (centre_uid, text) => {
            let obj_return = {error: 0, message:""};
    
            try{
                /* get active staff, token has been passed in header in useEffect */
                let field = "id, name, ic, phone, hourly_rate, centre_uid, job_role, status",
                    query = centre_uid ? `(status=1 AND centre_uid="${centre_uid}")` : `status=1`;

                query = query !== "" 
                            ? (text ? `${query} AND (ic LIKE "${text}" OR name LIKE "${text}")` : query)
                            : (text ? `(ic LIKE "${text}" OR name LIKE "${text}")` : "");
                query = query !== "" ? `&query=${query}` : "";
          
                const axiosOptions = {
                    headers: {
                        token: localStorage.getItem('token'), 
                    },
                };
                let res = await axios.get(`${config.app.back_end.url}/Staff?field=${field}${query}`, axiosOptions);
                    res.data = res.data.data;
                arr_staff_searched.current = res.data;
                
                /* transform job_role from JSON to array */
                res.data.map(item => {
                    if(item.job_role) item.job_role = JSON.parse(item.job_role);
                    return item;
                })
    
                status.current = "staff_listed";
                dispatchEventTable('SET_DATA', res.data);
            }
            catch(err){
                if(err.fn === "Staff.list")
                    obj_return = err;
                else{
                    obj_return.error = 2;
                    obj_return.message = "Failed to retrieve staff listing.";
                }
            }
            finally{
                if(obj_return.error) throw obj_return;
                else return obj_return;
            }
        }, [])

        const callBackEvent = useCallback(async (action, data) => {
            if(["onRowsSelected", "onAllRowsSelected"].includes(action)){
                if(data.length) status.current = "staff_selected";
                else status.current = "staff_listed";
                staff_selected.current = data;
            }
            else if(action === "onRequestSearch"){ /* search bar event: action:onRequestSearch, data: {name, value} */
                searchStaffs(null,  data.value);
            }
        }, [])

        useEffect(async () => {
            await searchStaffs();
        }, [searchStaffs]);

        /*
        const { loaded, error, data: staffs } = useQueryWithStore({
            type: 'GET_LIST',
            resource: 'Staff',
        });
    
        if (!loaded) { return <Loading />; }
        if (error) { return <Error />; }
        if(!staffs) return null;
        */

        return (
        <FormWithRedirect
            {...props}
            /* very important, redirect to list immediate to avoid 
             * call getOne /TimeSheet/undefined
             * Unkown why after creating and call getOne with undefined yet
            */
            redirect={`/TimeSheet`}
            render={formProps => (
                /* here starts the custom form layout, prevent enter key cause page refresh */
                <form onSubmit={(e)=> e.preventDefault()}>
                    {/* width="50%" set layout 50% width only, can adjust this one */}
                    <Box p="1em" width="97%">
                        <Box display="flex" ml="0.5em" mb = "1em" width={1}>
                            <Field style={{width:"100%"}}
                                name={"search_bar"}
                                component={SearchBar}
                                callbackevent={callBackEvent}
                            />
                        </Box>
                        <Box display="flex" ml="0.5em" mb = "1em">
                            <Table
                                toolbarTitle={'Active Staff Listing'}
                                toolbarFilterAction={false}
                                toolbarDeleteAction={false}
                                headCells={[
                                    { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
                                    { id: 'ic', numeric: true, disablePadding: false, label: 'NRIC' },
                                    { id: 'phone', numeric: true, disablePadding: false, label: 'Phone' },
                                    { id: 'hourly_rate', numeric: true, disablePadding: false, label: 'Hourly rate' },
                                    { id: 'job_role', numeric: true, disablePadding: false, label: 'Job role' },
                                ]}
                                thCells={['name']}
                                rowsPerPageOptions={[4, 10, 25]}
                                showEmptyRows={false}
                                multipleRowSelection={false}
                                callbackevent={callBackEvent}
                                dispatchevent={childFn => dispatchEventTable = childFn}
                            >
                            </Table>
                        </Box>
                        <Box display="flex" ml="0.5em" mb = "1em">
                            <Field required
                                validate={requiredValidate(true)}
                                label="Select centre"
                                name="centre_uid"
                                component={SelectInput}
                                optionValue="uid"
                                choices={centres}
                            />
                            {/*
                            <SelectInput 
                                label="Select centre"
                                source="centre_uid"
                                optionValue="uid"
                                choices={centres}
                            />
                            */}
                        </Box>
                        <Box display="flex">
                            <Box>
                                {/*<TextInput source="date" fullWidth/>*/}
                                <DateInput validate={[required()]}
                                    label="Date"
                                    source="date" 
                                    initialValue={new Date().toISOString().split("T")[0]}
                                />
                            </Box>
                            <Box flex={1} ml="0.5em">
                                {/*<TextInput source="start" fullWidth/>*/}
                                <Field required
                                    validate={requiredValidate(true)}
                                    label={"Start"}
                                    name={"start"}
                                    component={SliderTimeSlot}
                                    min={START_RANGE.min}
                                    max={START_RANGE.max}
                                    step={START_RANGE.step}
                                />
                            </Box>
                            <Box flex={1} ml="1em">
                                {/*<TextInput source="end" fullWidth/>*/}
                                <Field required
                                    validate={requiredValidate(true)}
                                    label={"End"}
                                    name={"end"}
                                    component={SliderTimeSlot}
                                    min={END_RANGE.min}
                                    max={END_RANGE.max}
                                    step={END_RANGE.step}                                />
                            </Box>
                            <Box flex={1} /*style={{flexGrow:1, flexShrink:1, flexBasis: 'auto'}}*/ ml="1em">
                                {/*<TextInput source="break" fullWidth/>*/}
                                <Field required
                                    validate={requiredValidate(true)}
                                    label={"Break"}
                                    name={"break"}
                                    component={SliderTimeSlot}
                                    min={BREAK_RANGE.min}
                                    max={BREAK_RANGE.max}
                                    step={BREAK_RANGE.step}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Toolbar>
                        <Box display="flex" justifyContent="space-between" width="100%">
                            <SaveButton
                                saving={formProps.saving}
                                handleSubmitWithRedirect={
                                    () => handleSubmit(formProps.handleSubmitWithRedirect, formProps.record)
                                }
                                transform={transform}
                            />
                        </Box>
                    </Toolbar>
                </form>
            )}
        />
        );
    }

    return (
        <Create {...props} undoable={false}>
            <TimeSheetCreateForm />
        </Create>
    );
}