import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MessageBox from './MessageBox'

const useStyles = makeStyles(theme => ({
	margin: {
		margin: theme.spacing(1),
	},
}));

export default function MessageBar(props) {
	const classes = useStyles();
	const { className, message, variant, duration, show, 
			vertical='bottom', horizontal='center',
			close, action,
			...other } = props;
	const [open, setOpen] = useState(show);

	/* Post-render lifecycle
	 * pass an empty array to useEffect, it’s only executed after the first render
	 * componentDidMount, componentWillUnmount: code in return part
	*/
	useEffect(() => {
		/* clean up code */
		return () => {
		}
	}, [])

	const handleClose = (event, reason) => {
		setOpen(false);
		if (reason === 'clickaway')
			return;
	};

	let vert = vertical,
		style = null;
	if(vert === 'middle'){
		style = { height: "100%"};
		vert = 'bottom';
	}

	return (
		<div>
		  <Snackbar
		  	style={style}
			anchorOrigin={{ vertical: vert, horizontal }}
			open={open}
			autoHideDuration={duration ? duration : null}
			onClose={handleClose}
		  >
			<MessageBox
			  onClose={close? handleClose : null}
			  variant={variant}
			  className={className}
			  message={message}
			  action={action}
			  {...other}
			/>
		  </Snackbar>
		</div>
	);
}