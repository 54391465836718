import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';

export const GroupList = props => {
    return (
        <List {...props}>
            <Datagrid rowClick="edit">
                <TextField source="id" label="No"/>
                <TextField source="name" />
                <TextField source="description" /> 
            </Datagrid>
        </List>
    )
};