import sha256 from 'crypto-js/sha256';
import sha512 from 'crypto-js/sha512';
import {unicodeStringToTypedArray, bs58Encode} from '../utils/string';

import config from 'config';

import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin';

export default (logout, type, params) => {
    const apiUrl = config.app.back_end.url;

    const auth = (logout, type, params) => {
        /* called when the user login */
        if (type === AUTH_LOGIN) {
            const   { username = null, password = null, 
                    accountInfo = null} = params;

            const   encrypted_password = password ? ""+sha256(""+sha512(password)) : null;
            const   {account = null , jwtIdToken: token = null} = accountInfo;
            const   BASE58 = '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';
            
            let bs58Token =  bs58Encode(unicodeStringToTypedArray(token), BASE58);
            const request = new Request(`${apiUrl}/User/auth?user_name=${username}&password=${encrypted_password}&token=${token}`, {
                method: 'GET',
                headers: new Headers({ 'Content-Type': 'application/json' }),
            })
            return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({data}) => {
                if(data.token) localStorage.setItem('token', data.token);
                if(data.user_name) localStorage.setItem('user_name', data.user_name);
                if(data.centre_email) localStorage.setItem('centre_email', data.user_name);
                if(data.group) localStorage.setItem('group', data.group);
                if(data.activity) localStorage.setItem('activity', data.activity);
        
                return Promise.resolve(data);
            });
        }
        /* called when the user clicks on the logout button */
        if (type === AUTH_LOGOUT) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_name');
            localStorage.removeItem('centre_email');
            localStorage.removeItem('group');
            localStorage.removeItem('activity');
            /* localStorage.setItem('obj_data', JSON.stringify(props.location.obj_data)
             * in components\Timeline\Timeline.js
            */
            localStorage.removeItem('obj_data');
            
            /* User admin login -> User -> logout.
             * Other user login -> system access User.
             * Workaround is to replace login URL when logout.
             * It isn't the nicest solution imo but it get's the job done.
            */
            logout();
            window.location = '/#';
            return Promise.resolve();
        }
        /* called when the API returns an error */
        if (type === AUTH_ERROR) {
            const { status } = params;
            if (status === 401 /*|| status === 403 */) {
                localStorage.removeItem('token');
                return Promise.reject();
            }
            return Promise.resolve();
        }
        /* called when the user navigates to a new location */
        if (type === AUTH_CHECK) {
            return localStorage.getItem('token')
                ? Promise.resolve()
                : Promise.reject();
        }
        return Promise.resolve();
    }

    return (type, params) => {
        return auth(logout, type, params);
    };
};