import React from "react";
import { Layout } from 'react-admin';
import _AppBar from './AppBar';
import SideBar from './SideBar';
import AppMenu from './AppMenu';
import AppNotification from './AppNotification';

const AppLayout = (props) => {
	return (
		<Layout 
			{...props}
			appBar={_AppBar}
			sidebar={SideBar}
			menu={AppMenu} 
			notification={AppNotification} 
		/>
	);
}

export default AppLayout;