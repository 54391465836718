import React  from 'react';
import { makeStyles } from '@material-ui/core';
import { ImageField as _ImageField } from 'react-admin';

const useImageFieldStyles = makeStyles({
    list: {
        display: 'inline-block',
        /*listStyleType: 'none',*/
        paddingLeft: 0,
    },
    image: {
        margin: 0,
        /* IC size: 325, 208 */
        width: "520px",
        maxHeight: 'none'
    }
});

const ImageField = ({ record, source, ...rest }) => {
    const imageFieldClasses = useImageFieldStyles();

    if (typeof (record) == "string") {
        record = {
            [source]: record
        }
    }
 
    return (<_ImageField 
                classes={imageFieldClasses} 
                record={record} 
                source={source} 
                {...rest} 
            />)
};

export default ImageField;