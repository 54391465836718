import React, {cloneElement} from 'react';
import { List, Datagrid, TextField, EmailField, 
         TextInput, ExportButton, downloadCSV, 
         Filter } from 'react-admin';

import jsonExport from 'jsonexport/dist';

import { makeStyles } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';


const useFilterStyles = makeStyles({
    job_role: {
         minWidth: '160px',
    },
});

const CentreFilter = props => {
    const classes = useFilterStyles();
    return (
        <Filter classes={classes} {...props}>
            <TextInput label="Code" source="uid" alwaysOn />
            <TextInput label="Name" source="name" alwaysOn />
        </Filter>
    );
}

/* customize exporter */
const exporter = centres => {
    const centresForExport = centres.map(centre => {
        /* ignore fields */
        const { id,...centreForExport } = centre;
        /* can add a field
        centreForExport.field = centre.field;
        */
        return centreForExport;
    });
    jsonExport(centresForExport, {
        /* order fields in the export */
        headers: ["uid", "name", "brand", "email"], 
        rename:["Code", "Name", "Brand", "Email"]
    }, (err, csv) => {
        downloadCSV(csv, 'centres'); // download as 'centres.csv` file
    });
};

/* customize actions in an list view */
const CentreActions = ({
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    permanentFilter,
    hasCreate,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
    /* NOTE: If we use react-admin Toolbar will cause resource is null when pass to provider.
     * Then export function will be crashed
    */
    <Toolbar>
        {filters && cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        {exporter !== false && (
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filter={{ ...filterValues, ...permanentFilter }}
                exporter={exporter}
            />
        )}
        {/* Add your custom actions
        <UIButton color="primary" onClick={null}>Custom</UIButton>
        */}
    </Toolbar>
);

export const CentreList = props => {
    return (
        <List {...props}
            actions={<CentreActions />}
            exporter={exporter}
            filters={<CentreFilter />} 
        >
            <Datagrid rowClick="edit">
                <TextField source="id" label="No"/>
                <TextField source="uid" label="Code"/> 
                <TextField source="name" />
                <TextField source="brand" />
                <EmailField source="email" />
            </Datagrid>
        </List>
    )
};