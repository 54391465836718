/* REFS
 * - https://codesandbox.io/s/f71wj?file=/demo.js:11015-11033
 * - https://marmelab.com/blog/2018/10/18/react-render-context-pattern.html
 * Ref to the second to put key prop for TableCell to avoid
 * `Warning: Each child in a list should have a unique "key" prop.`
*/
import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function MUITableHead(props) {
    const { classes,
            disabled,
            multipleRowSelection,
            numSelected, 
            order, 
            orderBy, 
            onSelectAllClick, 
            onRequestSort, 
            rowCount,
            headCells
    } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
          <TableRow>
            <TableCell key={"check.id"} padding="checkbox">
            {multipleRowSelection && (<Checkbox disabled={disabled}
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ 'aria-label': 'select all' }}
              />)
            }
            </TableCell>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
    );
}

MUITableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
        },
    title: {
        flex: '1 1 100%',
    },
}));

const MUITableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { toolbarTitle, textSelected, numSelected, 
            handleDelete, handleFilter} = props;

    return (
        <Toolbar
          className={clsx(classes.root, {
            [classes.highlight]: numSelected > 0,
          })}
        >
          {numSelected > 0 ? (
            <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
              {`${numSelected} [${textSelected}]`} selected
            </Typography>
          ) : (
            <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                {toolbarTitle}
            </Typography>
          )}

          {(handleDelete && numSelected) > 0 ? (
            <Tooltip title="Delete">
            <IconButton 
                aria-label="delete"
                onClick={handleDelete}
            >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (handleFilter) && (
            <Tooltip title="Filter list">
              <IconButton aria-label="filter list">
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          )}
        </Toolbar>
    );
};

MUITableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
        /*width: '100%',*/
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

export default props => {
    let {
        toolbarTitle,
        toolbarFilterAction,
        toolbarDeleteAction,
        headCells,
        thCells,
        rowsPerPageOptions,
        showEmptyRows,
        multipleRowSelection,
        disabled,
        custom,
        callbackevent, dispatchevent,
        ...rest
    } = props;

    if(!toolbarTitle) toolbarTitle = custom && custom.toolbarTitle;
    if(!toolbarFilterAction) toolbarFilterAction = custom && custom.toolbarFilterAction;
    if(!toolbarDeleteAction) toolbarDeleteAction = custom && custom.toolbarDeleteAction;
    if(!headCells) headCells = custom && custom.headCells;
    if(!thCells) thCells = custom && custom.thCells || [];
    if(!rowsPerPageOptions) rowsPerPageOptions = custom && custom.rowsPerPageOptions || [4, 10, 28];
    if(showEmptyRows === undefined) showEmptyRows = custom && custom.showEmptyRows;
    if(multipleRowSelection === undefined) multipleRowSelection = custom && custom.multipleRowSelection;

    const classes = useStyles();
    const [rowData, setRowData] = useState([]);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    /* selected is virual index on UI to select or un select item.
     * for instance the two first items on page 1 and two has the same index
    */
    const [selected, setSelected] = useState([]);
    /* actualSelected is actual index in data to select item */
    const [actualSelected, setActualSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(true);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

    /* move childListener before useEffect to avoid the error
     * ReferenceError: can't access lexical declaration 'childListener' before initialization
     * NOTE: the error does not happen in uix because in the file .babelrc has definition
     * "@babel/plugin-transform-runtime"
    */
    const childListener = useCallback((action, data) => {
        switch(action) {           
            case "SET_DATA":
                setRowData(data);
            break;
        }
    }, [])

    useEffect(() => {
        /* setup function that will receieve call from parent */
        dispatchevent && dispatchevent(childListener);

        /* clean up code */
        return () => {
        }
    }, [dispatchevent, childListener])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        let newSelecteds = [];
        if (event.target.checked)
            newSelecteds = rowData.map((item, idx) => idx);
        
        let actualSelectedIndexes = newSelecteds.map(v => (page > 0 ? v + page*rowsPerPage : v));
        callbackevent && callbackevent("onAllRowsSelected", actualSelectedIndexes);

        setSelected(newSelecteds);
        setActualSelected(actualSelectedIndexes);
    };

    const handleClick = (event, idx) => {
        if(disabled) return;

        const selectedIndex = selected.indexOf(idx);
        let newSelected = [];

        if(multipleRowSelection){
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, idx);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1),
                );
            }
        }
        else{
            newSelected = selectedIndex === -1 ? [idx] : [];
        }

        let actualSelectedIndex = newSelected.map(v => (page > 0 ? v + page*rowsPerPage : v));
        callbackevent && callbackevent("onRowsSelected", actualSelectedIndex);

        setSelected(newSelected);
        setActualSelected(actualSelectedIndex);
    };

    const handleFilter = (event) => {
        callbackevent && callbackevent("onFilter", null);
    }
    const handleDelete = (event) => {
        callbackevent && callbackevent("onDeleted", selected);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);

        /* reset selected item of the previuos page */
        if(!selected.length) return;
        callbackevent && callbackevent("onRowsSelected", []);
        setSelected([]);
        setActualSelected([]);

    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (idx) => selected.indexOf(idx) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rowData.length - page * rowsPerPage);

    return (
        <div className={classes.root}>
        <Paper className={classes.paper}>
            <MUITableToolbar
                toolbarTitle={toolbarTitle}
                textSelected={actualSelected.length ? actualSelected.map(idx => rowData[idx].name).join(", ") : null}
                numSelected={actualSelected.length}
                handleFilter={toolbarFilterAction ? handleFilter : null}
                handleDelete={toolbarDeleteAction ? handleDelete : null}
            />
            <TableContainer>
            <Table
                className={classes.table}
                aria-labelledby="muiTable"
                size={dense ? 'small' : 'medium'}
                aria-label="mui table"
            >
                <MUITableHead
                    disabled={disabled}
                    classes={classes}
                    multipleRowSelection={multipleRowSelection}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rowData.length}
                    headCells={headCells}
                />
                <TableBody>
                    {stableSort(rowData, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((rowData, index) => {
                        const isItemSelected = isSelected(index);
                        const labelId = `mui-table-checkbox-${index}`;

                        return (
                            <TableRow
                                hover={!disabled}
                                key={index}
                                onClick={(event) => handleClick(event, index)}
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                selected={isItemSelected}
                            >
                                <TableCell key={labelId} padding="checkbox">
                                    <Checkbox disabled={disabled}
                                        checked={isItemSelected}
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </TableCell>

                                {headCells.map((headCell) => {
                                    if(thCells.includes(headCell.id)){
                                        return (
                                            <TableCell key={headCell.id} component="th" id={labelId} scope="row" padding="none">
                                                {rowData[headCell.id]}
                                            </TableCell>
                                        );
                                    
                                    }
                                    else{
                                        return (
                                            <TableCell key={headCell.id} align="right">
                                                {!Array.isArray(rowData[headCell.id])
                                                    ? rowData[headCell.id]
                                                    : rowData[headCell.id].map(item => <Chip label={item} key={item}/>)
                                                }
                                            </TableCell>
                                        );
                                    }
                                })}
                            </TableRow>
                        );
                    })}
                    {(showEmptyRows && emptyRows > 0) && (
                        <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={2}>
                            <FormControlLabel
                                control={<Switch checked={dense} onChange={handleChangeDense} />}
                                label="Dense"
                            />
                        </TableCell>
                        <TableCell colSpan={4}>
                            <TablePagination
                                rowsPerPageOptions={rowData.length > rowsPerPage ? rowsPerPageOptions : []}
                                component="div"
                                count={rowData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
            </TableContainer>
        </Paper>
        </div>
    );
}
