import axios from "axios";
import * as SparkMD5 from 'spark-md5';

/* size of each chunk, set to 1 MB */
const chunkSize = 1 * 1024 * 1024;

export const streamFile = async (id, token, api_stream) => {
    const axiosOptions = {
        headers: {
            token: token, 
        },
    };

    let res = await axios.get(`${api_stream}/${id}`, axiosOptions);
}


export const hashFile = (file) => {

    return new Promise((resolve, reject) => {
        
        
        /* Use the Blob.slice method to split the file.
         * This method is also used differently in different browsers.
        */
        /*
        const blobSlice =
            File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
        */

        const chunks = Math.ceil(file.size / chunkSize);
        let currentChunk = 0;
        const spark = new SparkMD5.ArrayBuffer();
        const fileReader = new FileReader();
        function loadNext() {
            const start = currentChunk * chunkSize;
            const end = start + chunkSize >= file.size ? file.size : start + chunkSize;
            /* fileReader.readAsArrayBuffer(blobSlice.call(file, start, end)); */
            fileReader.readAsArrayBuffer(file.slice(start, end));
        }
        fileReader.onload = e => {
            spark.append(e.target.result); // Append array buffer
            currentChunk += 1;
            if (currentChunk < chunks) {
                loadNext();
            } else {
                const result = spark.end();
                // If result s are used as hash values only, if the contents of the file are the same and the names are different
                // You cannot keep two files if you want to.So add the file name.
                const sparkMd5 = new SparkMD5();
                sparkMd5.append(result);
                sparkMd5.append(file.name);
                const hexHash = sparkMd5.end();
                resolve(hexHash);
            }
        };
        fileReader.onerror = () => {
            console.warn('File reading failed!');
        };

        loadNext();
    }).catch(err => {
        throw err;
    });
};

const uploadFile = async (file, token, api_upload, api_merge, fnUploadProgress) => {
    const axiosPromiseArray = [];
    let hash = null,
        szFileName = null,
        nSize = 0,
        nUploadedBytes = 0,
        nUploadedChunks = 0;
    
    try{
        const chunks =  Math.ceil(file.size / chunkSize);
        hash = await hashFile(file);
        szFileName = file.name;
        nSize = file.size;

        for (let i = 0; i < chunks; i++) {
            const start = i * chunkSize;
            const end = Math.min(nSize, start + chunkSize);
            /* build upload form */
            const form = new FormData();
            form.append('file', file.slice(start, end));
            form.append('name', szFileName);
            form.append('total', chunks);
            form.append('index', i);
            form.append('size', nSize);
            form.append('hash', hash);
            /* ajax submits a slice, where content-type is multipart/form-data */
            const axiosOptions = {
                headers: {
                    token: token,
                    "Content-Type": "multipart/form-data"
                },
                onUploadProgress: e => {
                    /* progress in processing uploads */
                    // console.log(chunks, i, e, file);
                    nUploadedBytes += e.loaded;
                    if(e.loaded == e.total) ++nUploadedChunks;
                    if(fnUploadProgress) fnUploadProgress(nUploadedChunks, chunks, nUploadedBytes, nSize);
                },
            };
            /* add to Promise Array */
            axiosPromiseArray.push(axios.post(api_upload, form, axiosOptions));
        }

        /* request merge of slice files after all slice uploads */
        await axios.all(axiosPromiseArray)
        /* if want to get detail for each api call */
        /*
        .then(axios.spread((...responses) => {
        }))
        */
        /*
        .then(() => {
            // merge chunks block
            const data = {
                size: file.size,
                name: file.name,
                total: chunks,
                hash
            };
            axios
                .post(api_merge, data, { headers: { token: token } })
                .then(res => {
                    alert('Upload Successful');
                    return file;
                    
                })
                .catch(err => {
                    throw err;
                });
        });
        */
        /* replace merge chunk block with this */
        const data = {
            size: file.size,
            name: file.name,
            total: chunks,
            hash
        };
        await axios.post(api_merge, data, { headers: { token: token } });
    }
    catch(err){
        throw err;
        return;
    }
    finally{
        file.hash = hash;
        return file; // return {...file, hash: hash} does not work correctly.      
    }
    
};

export default uploadFile;