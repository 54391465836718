import React, { Component, Fragment } from "react";
import { fetchStart, fetchEnd, GET_LIST, CREATE, 
		 Toolbar, 
		 TextInput, ReferenceArrayInput, SelectArrayInput, PasswordInput,
		 regex
		} from 'react-admin';

import { Form, Field } from 'react-final-form';

import { Box } from '@material-ui/core';
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import SelectField from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

import {AppDataConsumer} from '../../context/AppData';

import config from 'config';

/* custom components */
import MessageBar from '../Message/MessageBar';
import dataProvider from '../../app/dataProvider';

/* custome validation */
import {required, passwordValidation, emailValidation} from "../../utils/string";

import sha256 from 'crypto-js/sha256';
import sha512 from 'crypto-js/sha512';

const FormRequired = value => {
	return (value ? undefined : "Required");
}

class UserCreateButton extends Component{
	constructor(props) {
        super(props);

        this.state = {
			error: false,
			error_message: null,
			showSelectCentre: false,
			showDialog: false,
			isSubmitting: false,
		};
       
        this.arrCentre = null;
        this.appData = null;
    }

	async componentDidMount() {

		/* only can use hook un function component like
		 * import useAppData from '../../hooks/useAppData';
		 * const {data, saveData} = useAppData();
		 * So this.appData is set by Consumer in render function
		*/
		/* if Signed user who has Administrator role then add
		 * Administrator role into dropdown list 
		*/
		/*
		if(this.appData.group.includes("Administrator")){
			config.user.ROLE.unshift({
	            lable:"Administrator",
	            key_string: 1,
	            enable: true,
	        })
		}
		*/
		if(this.appData.group.includes("Administrator"))
			config.user.ROLE[0].enable = true;
		else
			config.user.ROLE[0].enable = false;

        fetchStart();
        dataProvider()(GET_LIST, "Centre", {})
        .then((res) => {
        	this.setState({ error: false, showDialog: false, isSubmitting: false });
        	this.arrCentre = res.data;
        })
        .catch(error => {
        })
        .finally(() => {
        	fetchEnd();
        })
    }

    handleChangeRole = (input, event) => {
    	input.onChange(event.target.value);
		this.setState({showSelectCentre: event.target.value == 4 ? true : false});
	}

    renderSelectField = ({
		input,
		label,
		style,
		className,
		meta,
		children,
		...custom
		}) => {

		return(
			<Fragment>
			<FormControl 
				className={className} 
				{...input}
			>
				<InputLabel /*style={{ marginLeft: "12px"}}*/>{label}</InputLabel>
				<SelectField
					/*floatingLabelText={label}*/
					/* errorText not working, so self implement below */
					/*errorText={meta.touched && meta.error}*/
					{...input}
					
					children={children}
					{...custom}
					onChange={event => this.handleChangeRole(input, event)}
				/>
			</FormControl>
			<div>
			{/*equivalent CSS with classes below: style={{color:"#f44336", marginLeft: "14px", marginRight: "14px", fontSize: "12px", fontWeight: 400, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'}} */}
			{meta.error && meta.touched && <span className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense">{meta.error}</span>}
			</div>
			</Fragment>
		);
	}

    renderSelectRole() {
		return config.user.ROLE.filter(data => data.enable).map((data, idx) => {
			return (
				<MenuItem
					key={data.key_string}
					value={data.key_string}>
					{data.lable}
				</MenuItem>
			);
		});
	};

	handleClick = () => {
		this.setState({ error:false, showDialog: true });
	};

	handleCloseClick = () => {
		this.setState({ error:false, showDialog: false });
	};

	handleSubmit = async json => {
		/* very important to make MessageBar works correctly */
		this.setState({ error: false, isSubmitting: true });
		
		let key_value_user = {},
			key_value_user_activity = {user_id: null, group: null, activity: null, on: null};

		for(let key in json){
			if(key_value_user_activity.hasOwnProperty(key)){
				key_value_user_activity[key] = json[key];
				if(key === "group")
					key_value_user_activity[key] = `[${json[key]}]`;
				else if(key === "on")
					key_value_user_activity[key] = `{"centre":[${'"' + json[key].join('","') + '"'}]}`;
			}
			else{
				key_value_user[key] = json[key];
				if(key === "password")
					key_value_user[key] = ""+sha256(""+sha512(key_value_user[key]));
			}
		}

		/* if user authenticate with Azure AD then password will be set same as email internally */
		if(!key_value_user.password)
			key_value_user.password = ""+sha256(""+sha512(key_value_user['user_name']));

		/* add some fields are required by User API */
		if(!key_value_user.email) key_value_user.email = key_value_user.user_name;

		/* user has default permissions on the centre email 
		 * if user has Centre role then set permission on the centre_email.
		*/
		/*
		if(!key_value_user_activity.on && 
			json.group && json.group == 4)
			key_value_user_activity.on = `{"centre":[${'"' + key_value_user.email + '"'}]}`;
		*/

		/* call User API to create an user
		 * dispatch an action letting react-admin know a API call is ongoing
		 * 
		 * NOTE:
		 *	As we want to know when the new activity has been created in order to close the modal,
		 *	we use the dataProvider directly.
		*/
    	try{
    		fetchStart();
    		/* create user first */
    		let res = await dataProvider()(CREATE, "User", { data: key_value_user });
    		
    		/* assign user to resource that can manipulate on */
			/* add some fields require by assign User API */
			key_value_user_activity.user_id = res.data.user_id;
			key_value_user_activity.uri = "assign";
    		await dataProvider()(CREATE, "User", { data: key_value_user_activity });
	    	/*
			dataProvider()(CREATE, "User", { data: key_value_user })
			.then((res) => {
				this.setState({ error: false, showDialog: false, isSubmitting: false });
			})
			.catch(error => {
				this.setState({ error: true, error_message: error.message, isSubmitting: false});
			})
			.finally(() => {
				// letting react-admin know a API call has ended 
				// and call parent refresh hook (useRefresh)
				fetchEnd();
				this.props.refresh();
			});
			*/	
			this.setState({ error: false, showDialog: false, isSubmitting: false });
		}
		catch(error){
			this.setState({ error: true, error_message: error.message, isSubmitting: false});
		}
		finally{
			fetchEnd();
			this.props.refresh();
		}
	};

	render() {

		const { error, error_message, showSelectCentre, showDialog, isSubmitting } = this.state;
		return(
			<AppDataConsumer>
				{({data, saveData}) => {
					this.appData = data;

					return (
						<Fragment>
							<Button 
								color="primary"
								startIcon={<AddIcon />}
								onClick={this.handleClick}
							>Add
							</Button>
							<Dialog
								fullWidth
								open={showDialog}
								onClose={this.handleCloseClick}
								aria-label="Add user"
							>
								<DialogTitle>Add user</DialogTitle>
								<DialogContent>
									<Form
									    onSubmit={this.handleSubmit}
									    render={({ handleSubmit, form, submitting, pristine, values }) => (

							            <form onSubmit={handleSubmit}>
							                <Box>
												<Box display="flex">
													<Box flex={1} mr="0.5em">
														<TextInput validate={[required(), emailValidation()]} source="user_name" resource="User" label="Email *" fullWidth />
													</Box>
													{/*
													<Box flex={1} ml="0.5em">
														<PasswordInput validate={[required(), passwordValidation()]} source="password" label="Password *" fullWidth inputProps={{ autocomplete: 'current-password' }} />
													</Box>
													*/}
												</Box>

												<Box display="flex">
													<Box flex={1} mr="0.5em">
														<TextInput /*validate={[required()]}*/ source="first_name" resource="User" fullWidth />
													</Box>
													<Box flex={1} ml="0.5em">
														<TextInput /*validate={[required()]}*/ source="last_name" resource="User" fullWidth />
													</Box>
												</Box>
												
							                    <Box display="flex" />
							                    <Box flex={1}>
													<Field
														validate={FormRequired}
														className="box-fullwith"
														label="Role *"
														name="group" /* role */
														component={this.renderSelectField}
													>
														{this.renderSelectRole()}
													</Field>
							                    </Box>

							                    {showSelectCentre && <Box mt="0.4em">
													<SelectArrayInput 
														validate={[required()]}
														fullWidth={true} 
														label="Select centre"
														name="on"
														optionValue="uid"
														choices={this.arrCentre}/>
							                    </Box>}

							                	{error && <Box mt="0.2em" >
									                <MessageBar
									                	show={error}
												        variant="error"
												        message={error_message}
												    />
								                </Box>}
								            </Box>
							                <Toolbar>
							                    <Box display="flex" justifyContent="space-between" width="100%">
							                    	{/* SaveButton not submitting form, that why use materia;-ui button */}
										            <Button 
										            	disabled={isSubmitting || submitting || pristine} 
										            	type="submit"
										            	variant="contained" color="primary"
										            	startIcon={<SaveIcon />}
										            >Submit
										            </Button>
							                		<Button onClick={this.handleCloseClick}
							                			variant="outlined"
														startIcon={<CancelIcon />}
													>Cancel
													</Button>
							                    </Box>
							                </Toolbar>
							            </form>
								        )}
								    />
								</DialogContent>
							</Dialog>
						</Fragment>
					);
				}
			}
			</AppDataConsumer>
		)
	}
}

export default UserCreateButton;