import React, { useEffect, useState, useRef } from 'react';

import config from 'config';
import { Admin, Resource } from 'react-admin';
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin';

import { AzureAD, AuthenticationState } from 'react-aad-msal';
import {authAADProvider} from './app/authAADProvider';

import AppLayout from './layout/AppLayout';
import Theme from './layout/Theme';

import closeSidebarSaga from './sagas/closeSidebarSaga';
import LoginPage from './LoginPage';

import authProvider from './app/authProvider';
import dataProvider from './app/dataProvider';
import {AppDataProvider} from './context/AppData';
import MessageBar from './components/Message/MessageBar';

/* import activityReducer from './reducers/activityReducer';*/

import { UserList, UserEdit } from './user';
import { CentreList } from './centre';
import { GroupList } from './group';
import { StaffList, StaffEdit } from './staff';
import { TimeSheetList, TimeSheetEdit, TimeSheetCreate } from './timesheet';

const AdminApp = ({action, login, logout, accountInfo}) => {
    const [auth, setAuth] = useState(null);
    const objAuth = useRef(null);

    useEffect(()=>{
        if(!objAuth.current){
            /* use IIFE to define local async await */
            (async function _authAPI() {
                objAuth.current = await authProvider(logout)(AUTH_LOGIN, {accountInfo});
                /* convert activity into json so can be use directly later */
                //if(objAuth.current.activity) objAuth.current.activity = JSON.parse(objAuth.current.activity);
                setAuth(AUTH_LOGIN);
            })();
        }
    }, [objAuth.current]);

    const handleLogout = async () => {
        await authProvider(AUTH_LOGOUT);
        setAuth(AUTH_LOGOUT);
        logout();
    }

    if(objAuth.current){
        if(!objAuth.current.user_name){
            const message = `System got an inernal error. Please contact system admin.`;
            return (<MessageBar
                        show={true}
                        variant='error'
                        vertical='middle'
                        horizontal='center'
                        message={<div dangerouslySetInnerHTML={{__html: message}} />}
                    />);
        }
        else if(!objAuth.current.user_id){
            const message = `Please create user <strong>${objAuth.current.user_name}</strong> and assign security roles first`;
            return (<MessageBar
                        show={true}
                        variant='error'
                        vertical='middle'
                        horizontal='center'
                        message={<div dangerouslySetInnerHTML={{__html: message}} />}
                        action={{
                            variant: 'contained', 
                            size: 'small',
                            color: 'secondary',
                            text: 'Logout', 
                            onClick: handleLogout
                        }}
                    />);
        }

        return (
            <AppDataProvider values={objAuth.current}>
    
            <Admin
                /* customReducers={{ activity: activityReducer }} */
                customSagas={[ closeSidebarSaga ]}
                theme={Theme}
                layout={AppLayout}
                authProvider={authProvider(logout)}
                dataProvider={dataProvider()} /* pass function call because default params */
                loginPage={LoginPage}
            >
                <Resource 
                    name="User"
                    list={UserList} 
                    edit={UserEdit} 
                />
                <Resource name="Centre" list={CentreList} />
                <Resource name="Group" list={GroupList} />
                <Resource 
                    name="Staff" 
                    list={StaffList}
                    edit={StaffEdit} 
                />
                <Resource 
                    name="TimeSheet" 
                    list={TimeSheetList}
                    edit={TimeSheetEdit}
                    create={TimeSheetCreate}
                />
            </Admin>
            </AppDataProvider>
        );
    }
    else{
        return (
            <MessageBar
                show={true}
                variant='success'
                vertical='middle'
                horizontal='center'
                message="Authenticated successfully! Please wait a moment"
            />
        );
    }
}

const App = () => {
    let forceLogin = config.AAD.forceLogin;

    return(
        <AzureAD provider={authAADProvider} forceLogin={forceLogin}>
        {
            ({login, logout, authenticationState, error, accountInfo}) => {
                switch (authenticationState) {
                    case AuthenticationState.Authenticated:
                        return (
                            (<AdminApp
                                action={AUTH_LOGIN}
                                login={login}
                                logout={logout}
                                accountInfo={accountInfo} />)
                        );    
                    /* go in this case if forceLogin={false} */
                    case AuthenticationState.Unauthenticated:
                        return (
                           <MessageBar
                                show={true}
                                variant='info'
                                vertical='middle'
                                horizontal='center'
                                action={{variant: "secondary", text: "SUBMIT", onClick: login}}
                                message="Click SUBMIT to login with work email"
                            />
                        );
                    case AuthenticationState.InProgress:
                        return ( <MessageBar
                                    show={true}
                                    variant='info'
                                    vertical='middle'
                                    horizontal='center'
                                    message="Communicating with Azure AD ..."
                                />);
                }
            }
        }
        </AzureAD>
    );
}
 
export default App;
