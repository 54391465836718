import React, { /*useState*/ useRef  } from "react";

/* Make sure the shape of the default value passed to
   createContext matches the shape that the consumers expect!
   Note that saveData function here is just signature. The function
   can be implemented in other components like in App.js or in AppDataProvider
*/
/* const AppDataContext = React.createContext({data: {}, saveData: () => {}}); */

const AppDataContext = React.createContext();

export const AppDataProvider = ({ children, values }) => {
   /*const [data, setData] = useState (values ? values : {});*/
   const data = useRef(values ? values : {});

   const saveData = (values) => {
      /*setData(values);*/
      data.current = values;
   };

   return (
      <AppDataContext.Provider
         /*value={{data: data, saveData}}*/
         value={{data: data.current, saveData}}
      >
         {children}
      </AppDataContext.Provider>
   );
};

export const AppDataConsumer = AppDataContext.Consumer;

export default AppDataContext;