import React, { Fragment }  from 'react';

import { TextField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const useStyles = makeStyles({
    statusInactiveCell: {
        backgroundColor: '#f46e6e',
        color:'#fff',
        borderRadius: "4px",
    },
    statusDraftCell: {
        backgroundColor: '#616161',
        color:'#fff',
        borderRadius: "4px",
    }
    ,
    statusActiveCell: {
        backgroundColor: '#00b9f1',
        color:'#fff',
        borderRadius: "4px",
    }
});

const StatusFieldStaff = (props) => {
    const classes = useStyles();

    /* resolve TypeError: record is undefined when click on delete
     * in case delete record
    */
   if(!props.record) return null;

    return props.record ? (
        /* TextField is derived from Typography. So any props's Typography can be used
         * https://github.com/marmelab/react-admin/blob/master/packages/ra-ui-materialui/src/field/TextField.tsx
         * https://material-ui.com/api/typography/
        */
        <TextField
            source={props.source}
            className={classnames({
                [classes.statusDraftCell]: props.record[props.source] == 'draft' ? true : false,
                [classes.statusInactiveCell]: props.record[props.source] == 'inactive' ? true : false,
                [classes.statusActiveCell]: props.record[props.source] == 'active' ? true : false,
            })}
            align='center'
            display='block' /* make full width */
            {...props} 
            fullWidth={true}
        />
    ) : null
}

export default StatusFieldStaff;