import React from 'react';

import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import classnames from 'classnames';
/*import sanitizeFieldRestProps from 'ra-ui-materialui/esm/field/sanitizeRestProps';*/

import { Viewer, SpecialZoomLevel  } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

/* temporary disbale toolbar due to error
 * Invalid hook call. Hooks can only be called inside of the body of a function component
*/
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';

const useStyles = makeStyles({
    list: {
        display: 'inline-block',
        justifyContent: 'center',
        listStyleType: 'none',
        paddingLeft: 0,
    },
    centreItem:{
        display: 'flex',
        alignItems: 'center', 
        marginBottom: 16
    },
});

const PreviewPDFField = props => {
    const toolbarPluginInstance = toolbarPlugin();
    const { Toolbar } = toolbarPluginInstance;

    const {
        className,
        classes: classesOverride,
        emptyText,
        record,
        source,
        src,
        title,
        onClick,
        ...rest
    } = props;

    const sourceValue = get(record, source);
    const classes = useStyles(props);

    const handleClick = (e, val) =>{
        onClick(e, val);
    }

    if (!sourceValue) {
        return emptyText ? (
            <Typography
                component="span"
                variant="body2"
                className={className}
                {...rest/*...sanitizeFieldRestProps(rest)*/}
            >
                {emptyText}
            </Typography>
        ) : (
            <div className={className} {...rest/*...sanitizeFieldRestProps(rest)*/} />
        );
    }

    if (Array.isArray(sourceValue)) {
        return (
            <ul
                className={classnames(classes.list, className)}
                {...rest/*...sanitizeFieldRestProps(rest)*/}
            >
                {sourceValue.map((file, index) => {
                    const fileTitleValue = get(file, title) || title;
                    const srcValue = get(file, src) || file[src] || title;
                    const fileInfo = get(file, 'file') || null;
                  
                    return (
                        <li key={index} className={classes.centreItem}/*style={{display: 'flex', alignItems: 'center', marginBottom: 16}}*/>
                            <div
	                            style={{
	                                border: '1px solid rgba(0, 0, 0, 0.3)',
	                                display: 'flex',
	                                flexDirection: 'column',
	                                width:'520px',
	                                height: '800px',
	                            }}
	                        >
	                            <div
	                                style={{
	                                    alignItems: 'center',
	                                    backgroundColor: '#eeeeee',
	                                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
	                                    display: 'flex',
	                                    padding: '4px',
	                                }}
	                            >
	                                <Toolbar />
	                            </div>
	                            <div
	                                style={{
	                                    flex: 1,
	                                    overflow: 'hidden',
	                                }}
	                            >
	                                <Viewer
	                                    fileUrl = {srcValue}
                                        defaultScale={SpecialZoomLevel.PageFit}
	                                    plugins={[
	                                        toolbarPluginInstance,
                                        ]}
	                                />
	                            </div>
                        	</div>
                            <IconButton
                                aria-label="delete"
                                color="primary"
                                onClick={e=>handleClick(e, fileInfo)}
                            >
                                <DeleteForeverIcon style={{fill: "#f44336"}}/>
                            </IconButton>
                        </li>
                    );
                })}
            </ul>
        );
    }

    const fileTitleValue = get(record, title) || title;
    const fileInfo = get(record, 'file') || null;

    return (
        <div className={classes.centreItem} {...rest/*...sanitizeFieldRestProps(rest)*/}>
            <div
	            style={{
	                border: '1px solid rgba(0, 0, 0, 0.3)',
	                display: 'flex',
	                flexDirection: 'column',
	                width:'520px',
	                height: '800px',
	            }}
	        >
	            <div
	                style={{
	                    alignItems: 'center',
	                    backgroundColor: '#eeeeee',
	                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
	                    display: 'flex',
	                    padding: '4px',
	                }}
	            >
	                <Toolbar />
	            </div>
	            <div
	                style={{
	                    flex: 1,
	                    overflow: 'hidden',
	                }}
	            >
	                <Viewer
	                    fileUrl = {sourceValue}
                        defaultScale={SpecialZoomLevel.PageFit}
	                    plugins={[
	                        toolbarPluginInstance,
                        ]}
	                />
	            </div>
	    	</div>
            <IconButton
             	aria-label="delete"
	            color="primary"
	            onClick={e=>handleClick(e, fileInfo)}
            >
                <DeleteForeverIcon style={{fill: "#f44336"}}/>
            </IconButton>
        </div>
    );
};


export default PreviewPDFField;