import { stringify } from 'query-string';
import {
    fetchUtils,
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    UPDATE_MANY,
    DELETE,
    DELETE_MANY,
} from 'ra-core';

import config from 'config';
import httpClientToken from '../utils/httpClient';

import sha256 from 'crypto-js/sha256';
import sha512 from 'crypto-js/sha512';

/**
 * Maps react-admin queries to a simple REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST     => GET http://my.api.url/user?field=id,name&query=id = 1 OR user_name = 'test'&order_by=name&offset=0&count=10
 *
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (apiUrl = config.app.back_end.url, httpClient = httpClientToken) => {
    /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
    const convertDataRequestToHTTP = (type, resource, params) => {
        let url = '', arrQuery = [],
            select = null, objQuery = {};
        const options = {};

        switch (type) {
            case GET_LIST:
                const { page = null, perPage = null} = params && params.pagination || {};
                const { field = null, order = null } = params && params.sort || {};
                if(resource.toLowerCase() === "group"){
                    select = "id, name";
                    for(let key in params && params.filter)
                        arrQuery.push(`${key} LIKE '${params.filter[key]}'`);
                }
                else if(resource.toLowerCase() === "user"){
                    select = "id, user_name, first_name, last_name, email, user_group, activity_on";
                    for(let key in params && params.filter)
                        arrQuery.push(`${key} LIKE ${params.filter[key]}`);
                    /* only get active user */
                    arrQuery.push(`status = 1`);
                }
                else if(resource.toLowerCase() === "staff"){
                    select = "id, name, ic, employee_id, phone, email, centre_uid, hourly_rate, job_role, status, bank_name, bank_code, bank_account_name, bank_account_no, created_date_time";
                     /* only get un-deleted staff */
                    arrQuery.push(`deleted = 0`);

                    /* format filter: sql condition */
                    const eq_filter = {
                    };
                    const in_filter = {
                        job_role: true,
                    };
                    let op_comp = null,
                        value = null;

                    for(let key in params && params.filter){
                        if(params.filter[key] === "none") continue;

                        /* filter time slot range */
                        if(key === "created_from"){
                            op_comp = ">=";
                            value = params.filter[key] + " 00:00:00";
                            value = `"${value}"`;
                            key = "created_date_time";
                        }
                        else if(key === "created_to"){
                            op_comp = "<=";
                            value = params.filter[key] + "23:59:59";
                            value = `"${value}"`;
                            key = "created_date_time";
                        }
                        /* other filters */
                        else if(eq_filter[key]){
                            op_comp = "=";
                            value = isNaN(params.filter[key]) ? params.filter[key].toLowerCase() : params.filter[key];
                            value = `"${value}"`;
                        }
                        else if(in_filter[key]){
                            op_comp = "IN";
                            value = `('${params.filter[key].join("','")}')`;
                        }
                        else{
                            op_comp = "LIKE";
                            value = isNaN(params.filter[key]) ? params.filter[key].toLowerCase() : params.filter[key];
                            value = `"${value}"`;
                        }
                        arrQuery.push(`${key} ${op_comp} ${value}`);
                    }
                }
                else if(resource.toLowerCase() === "timesheet"){
                    select = "id, staff_id, staff_name, staff_nric, centre_uid, date, start, end, break, status";
                    /* only get un-deleted timesheet */
                    arrQuery.push(`deleted = 0`);
               
                    /* format filter: sql condition */
                    const eq_filter = {
                    };
                    const in_filter = {
                        status: true,
                    };
                    let op_comp = null,
                        value = null,
                        time_range_from = null,
                        time_range_to = null;

                    for(let key in params && params.filter){
                        if(params.filter[key] === "none") continue;

                        /* filter time slot range */
                        if(key === "created_from"){
                            op_comp = ">=";
                            value = params.filter[key]/* + " 00:00:00"*/;
                            value = `"${value}"`;
                            key = "date";
                        }
                        else if(key === "created_to"){
                            op_comp = "<=";
                            value = params.filter[key]/* + "23:59:59"*/;
                            value = `"${value}"`;
                            key = "date";
                        }
                        /* other filters */
                        else if(eq_filter[key]){
                            op_comp = "=";
                            value = isNaN(params.filter[key]) ? params.filter[key].toLowerCase() : params.filter[key];
                            value = `"${value}"`;
                        }
                        else if(in_filter[key]){
                            op_comp = "IN";
                            value = `('${params.filter[key].join("','")}')`;
                        }
                        else{
                            op_comp = "LIKE";
                            value = isNaN(params.filter[key]) ? params.filter[key].toLowerCase() : params.filter[key];
                            value = `"${value}"`;
                        }
                        arrQuery.push(`${key} ${op_comp} ${value}`);
                    }
                }
                else if(resource.toLowerCase() === "centre"){
                    for(let key in params && params.filter)
                        arrQuery.push(`${key} LIKE ${params.filter[key]}`);
                }
                
                if(select) objQuery.field = select;
                if(page && perPage){
                    objQuery.offset = (page - 1) * perPage;
                    objQuery.count = perPage;
                }
                if(order)
                    objQuery.order_by = `${field}  ${order}`;
                if(arrQuery.length) objQuery.query = arrQuery.join(" AND ");

                url = `${apiUrl}/${resource}?${stringify(objQuery)}`;
                break;
            case GET_ONE:
                if(resource.toLowerCase() === "user"){
                    select = "id, user_name, first_name, last_name, email, user_group";
                    arrQuery.push(`status = 1`);
                }
                else if(resource.toLowerCase() === "staff"){
                    select = "id, name, ic, employee_id, phone, email, centre_uid, hourly_rate, job_role, medical_report_date, status, files, bank_name, bank_code, bank_account_name, bank_account_no, created_date_time";
                }
                else if(resource.toLowerCase() === "timesheet"){
                    select = "id, staff_id, staff_name, staff_nric, centre_uid, date, start, end, break, status, signature";
                }
                
                objQuery = {
                    field: select
                };
                if(arrQuery.length) objQuery.query = arrQuery.join(" AND ");

                url = `${apiUrl}/${resource}/${params.id}?${stringify(objQuery)}`;
                break;
            case GET_MANY: {
                if(resource.toLowerCase() === "centre"){
                    /* name and email fields will be used in booking.js file in
                     * <SelectInput optionText="name" optionValue="email" />
                    */
                    objQuery.field = "uid, name";
                    objQuery.query = `uid IN (${params.ids.join(",")})`;
                }
                else if(resource.toLowerCase() === "group"){
                    objQuery.field = "id, name";
                    objQuery.query = `id IN (${params.ids.join(",")})`;
                }
                url = `${apiUrl}/${resource}?${stringify(objQuery)}`;
               
                break;
            }
            case GET_MANY_REFERENCE: {
                const { page, perPage } = params.pagination;
                const { field, order } = params.sort;
                objQuery = {
                    sort: JSON.stringify([field, order]),
                    range: JSON.stringify([
                        (page - 1) * perPage,
                        page * perPage - 1,
                    ]),
                    filter: JSON.stringify({
                        ...params.filter,
                        [params.target]: params.id,
                    }),
                };
                url = `${apiUrl}/${resource}?${stringify(objQuery)}`;
                break;
            }
            case UPDATE:
                if(resource.toLowerCase() === "staff"){
                    for(let key in params.data){
                        if( params.data[key] != params.previousData[key]){
                            objQuery[key] = params.data[key];
                            /* if a prop is an array, then convert into string to store in json field in back-end */
                            if(Array.isArray(objQuery[key])) objQuery[key] = JSON.stringify(objQuery[key]);
                        }
                    }
                }
                else if(resource.toLowerCase() === "user"){
                    /* get changed fields only */
                    for(let key in params.data){
                        if( (params.data[key] != params.previousData[key])){
                            if(key === "user_group")
                                objQuery[key] = `[${params.data[key]}]`;
                            else
                                objQuery[key] = params.data[key];
                            if(Array.isArray(objQuery[key])) objQuery[key] = JSON.stringify(objQuery[key]);
                        }
                        if(key === "password")
                            objQuery["new_password"] = ""+sha256(""+sha512(params.data[key]));
                        else if(key === "activity_on" && params.data[key]){
                            if(params.data[key].length)
                                objQuery["activity_on"] = `{"centre":[${'"' + params.data[key].join('","') + '"'}]}`;
                            else objQuery["activity_on"] = null;
                        }
                    }
                }
                else if(resource.toLowerCase() === "timesheet"){
                    for(let key in params.data){
                        if( params.data[key] != params.previousData[key]){
                            objQuery[key] = params.data[key];
                            /* if a prop is an array, then convert into string to store in json field in back-end */
                            if(Array.isArray(objQuery[key])) objQuery[key] = JSON.stringify(objQuery[key]);
                        }
                    }
                }
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = 'PATCH';
                options.body = JSON.stringify(objQuery);
                break;
            case CREATE:
                if(params.data && params.data.uri){
                    resource = `${resource}/${params.data.uri}`;
                    delete params.data.uri; 
                }

                url = `${apiUrl}/${resource}`;
                options.method = 'POST';
                options.body = JSON.stringify(params.data);
                break;
            case DELETE:
                if(resource.toLowerCase() === "user")
                    objQuery = {logical_delete: false};
                
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = 'DELETE';
                options.body = JSON.stringify(objQuery);
                break;
            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
        return { url, options };
    };

    const transformTimeSheetItem = (item) => {
        let start_hour, start_min, end_hour, end_min;
        if(item.start){
            start_hour = ("0".repeat(2)+Math.floor(item.start/60)).substr(-2);
            start_min = ("0".repeat(2)+item.start%60).substr(-2);
            item.start_slot = `${start_hour}:${start_min}`;
        }
        if(item.end){
            end_hour = ("0".repeat(2)+Math.floor(item.end/60)).substr(-2);
            end_min = ("0".repeat(2)+item.end%60).substr(-2);
            item.end_slot = `${end_hour}:${end_min}`;
        }
        if(item.status != undefined )
            item.status_lable = config.status.timesheet.mapping[item.status];
    }

    /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} Data response
     */
    const convertHTTPResponse = (response, type, resource, params) => {
        const { headers, json } = response;

        switch (type) {
            case GET_LIST:
            case GET_MANY_REFERENCE:
                /* ignore this header prop because we might use lazy loading */
                if (!headers.has('content-range')) {
                    throw new Error(
                        'The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?'
                    );
                }
                else if(resource.toLowerCase() === "centre"){
                }
                else if(resource.toLowerCase() === "staff"){
                    /* data transformation */
                    for(let i = 0, len = json.data.length; i < len; i++){
                        if(json.data[i].job_role)
                            json.data[i].job_role = JSON.parse(json.data[i].job_role);
                        if(json.data[i].status != undefined )
                            json.data[i].status = config.status.staff.mapping[json.data[i].status];
                    }
                }
                else if(resource.toLowerCase() === "timesheet"){
                    /* data transformation */
                    for(let i = 0, len = json.data.length; i < len; i++)
                        transformTimeSheetItem(json.data[i]);
                }
                else if(resource.toLowerCase() === "user"){
                    for(let i = 0, len = json.data.length; i < len; i++){
                        if(json.data[i].activity_on)
                            json.data[i].activity_on = JSON.parse(json.data[i].activity_on).centre;
                        /*
                        else if(json.data[i].hasOwnProperty("activity_on"))
                            json.data[i].activity_on = [];
                        */
                        if(json.data[i].user_group)
                            json.data[i].user_group = JSON.parse(json.data[i].user_group);
                        /*
                        else if(json.data[i].hasOwnProperty("user_group"))
                            json.data[i].user_group = [];
                        */
                    }
                }

                return {
                    data: json.data,
                    total: parseInt(
                        headers
                            .get('content-range')
                            .split('/')
                            .pop(),
                        10
                    ),
                };
            case GET_MANY: {
                /* User list uses centre_uid (activity_on) to make a reference to centre name in Centre.
                 * So need to add an additional column id to store email to make the reference works.
                */
                if(resource.toLowerCase() === "centre"){
                    for(let i = 0, len = json.data.length; i < len; i++)
                        json.data[i].id = json.data[i].uid;
                }
    
                return {
                    data: json.data,
                };
            }
            case GET_ONE:{
                /* user_group need to be converted into array to feed into SelectInput in UserEdit */
                if(resource.toLowerCase() === "user" && json.data.user_group)
                    json.data.user_group = JSON.parse(json.data.user_group);
                
                /* job_role need to be converted into array to feed into SelectArrayInput in StaffEdit */
                else if(resource.toLowerCase() === "staff" && json.data.job_role){
                    json.data.job_role = JSON.parse(json.data.job_role);
                    //json.data.job_role = JSON.parse(json.data.job_role).map(val => ({id:val, name:val}));
                }
                else if(resource.toLowerCase()=== "timesheet"){
                }
            
                return {
                    data: json.data,
                };
            }
            case CREATE:
                let objRet = {id: json.id};
                /* handle for User resource */
                if((resource.toLowerCase() === "user") && json.data && json.data.user_id)
                    objRet.user_id = json.data.user_id;

                return { data: { ...params.data, ...objRet } };
            case UPDATE:
                return { data: json.data };
            case DELETE_MANY: {
                return { data: json || [] };
            }
            default:
                return { data: json };
        }
    };

    /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a data response
     */
    return (type, resource, params) => {
        // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
        if (type === UPDATE_MANY) {
            return Promise.all(
                params.ids.map(id =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: 'PUT',
                        body: JSON.stringify(params.data),
                    })
                )
            ).then(responses => ({
                data: responses.map(response => response.json),
            }));
        }
        // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
        if (type === DELETE_MANY) {
            return Promise.all(
                params.ids.map(id =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: 'DELETE',
                    })
                )
            ).then(responses => ({
                data: responses.map(response => response.json),
            }));
        }
        
        const { url, options } = convertDataRequestToHTTP(
            type,
            resource,
            params
        );
        return httpClient(url, options).then(response =>
            convertHTTPResponse(response, type, resource, params)
        );
    };
};