import React, {useEffect, useCallback, useState} from 'react';

import {
    FormControl,
    FormLabel
} from '@material-ui/core';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import ValueLabel from "@material-ui/core/Slider/ValueLabel";

import { ErrorMessage, showError, useFieldForErrors } from './MUIUtils';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    margin: {
        height: theme.spacing(3),
    },
}));

const StyledValueLabel = withStyles({
    /*
    offset: {
        top: -28,
        left: props => props.index === 0 ? "calc(-50% + -20px)" : "calc(-50% + 12px)"
    },
    circle: {
        transform: props => props.index === 0 ? "rotate(-90deg)" : "rotate(0deg)"
    },
    label: {
        transform: props => props.index === 0 ? "rotate(90deg)" : "rotate(0deg)"
    }
    */
    label: {
        textAlign: 'center'
    },
    circle:{
        width: 34,
        height: 34
    }
})(ValueLabel);

const SliderTimeSlot = withStyles((theme)=>({
  root: {
      color: theme.palette.primary.light,/*theme.palette.primary.main,*/
      height: 2,
      paddingTop: 32,
  },
  track: {
      height: 2,
      borderRadius: 2,
  },
}))(Slider);

const valuetext = (value) => {
    return value;
}

export default props => {
    let {
        input, name, type, meta, label, required, disabled,
        min, max, step, val, custom,
        callbackevent, dispatchevent,
        helperText,
        inputLabelProps,
        formControlProps,
        formHelperTextProps,
        ...rest
    } = props;

    const { value, onChange, ...restInput } = input;
    const field = useFieldForErrors(name);
    const isMetaObj = (typeof field.meta === "object" || field.meta instanceof Object) ? true : false;
    const isError = showError(isMetaObj ? meta : field.meta); /* used field.meta but field.meta.error return object */
    const { error, submitError } = meta;

    formHelperTextProps = formHelperTextProps || {error: isError};
    
    if(min === undefined || min === null) min = custom && custom.min !== undefined ? custom.min : "08:00";
    if(max === undefined || max === null) max = custom && custom.max !== undefined ? custom.max : "17:00";
    if(step === undefined || step === null) step = custom && custom.step !== undefined ? custom.step : 30;
    if(val === undefined || val === null) val = custom && custom.val !== undefined ? custom.val : null;
    
    /* fix slider accepts 0 otherwise it show * Require as submit */
    const [_value, setValue] = useState(val);
    const [data, setData] = useState({});

    const childListener = useCallback((action, data) => {
        switch(action) {           
            case "SET_DATA":
                setData(prevState => {
                    return { ...prevState,
                        ...data.min !== undefined && data.min !== null  && { min: data.min },
                        ...data.max !== undefined && data.max !== null  && { min: data.max },
                        ...data.step  && { min: data.step },
                        ...data.range_map  && { range_map: data.range_map }
                    };
                });
            break;
            case "SET_VALUE":
                setValue(data);
            break;
        }
    }, [])

    /* Generate minute ranges for instance "15, ...., 45".
     * And return min, max in minutes
    */
   const genMinuteRanges = useCallback(() => {
        let res = {error: false, message: null, data: null};

        try{
            const ranges = [],
                range_map = {};

            let minutes = min;
            while(minutes <= max){
                ranges.push(minutes);
                range_map[minutes] = (minutes === 0 ? `${minutes} min` : `${minutes} mins`);
                minutes += step;
            }

            res.data = {
                min: min, 
                max:  max,
                step: step,
                ranges: ranges,
                range_map: range_map,
            }
        }
        catch(err){
            res.error = true;
            res.message = err.message ? err.message : err.toString();
        }
        finally{
            if(res.error) throw res;
            else return res;
        }
    }, [min, max, step])


    /* Generate time ranges for instance "08:30, ...., 17:30".
    * And return min, max in minutes
    */
    const genTimeRanges = useCallback((language) => {
        let res = {error: false, message: null, data: null};

        try{
            language = language || "en-GB";

            const arrStart = min.split(":"),
                arrEnd = max.split(":"),
                startHr = arrStart[0] ? parseInt(arrStart[0]) : 8,
                startMin  = arrStart[1] ? parseInt(arrStart[1]) : 0,
                endHr = arrEnd[0] ? parseInt(arrEnd[0]) : 17,
                endMin  = arrEnd[1] ? parseInt(arrEnd[1]) : 0;

            const ranges = [],
                range_map = {},
                totalMinInDay = 24 * 60,
                startDate = new Date(),
                endDate = new Date(),
                format = {
                    hour12: false,
                    hour: 'numeric',
                    minute: 'numeric',
                };

            
            startDate.setHours(startHr);
            startDate.setMinutes(startMin);
            endDate.setHours(endHr);
            endDate.setMinutes(endMin);

            let minutes = 0;
            while(minutes < totalMinInDay){
                ranges.push(startDate.toLocaleTimeString(language, format));
                range_map[`${startHr* 60 + minutes+startMin}`] = startDate.toLocaleTimeString(language, format);

                minutes += step;
                startDate.setHours(startHr);
                startDate.setMinutes(minutes+startMin);
                if(startDate > endDate) break;
            }

            res.data = {
                min: startHr*60 + startMin, 
                max:  endHr*60 + endMin,
                step: step,
                ranges: ranges,
                range_map: range_map,
            }
        }
        catch(err){
            res.error = true;
            res.message = err.message ? err.message : err.toString();
        }
        finally{
            if(res.error) throw res;
            else return res;
        }
    }, [min, max, step])

    const genRanges = useCallback((language) =>{
        let res = null;
        try{
            if(Number.isInteger(min) && Number.isInteger(max)){
                res = genMinuteRanges();
                setData(prevState => {
                    return { ...prevState, 
                        min: res.data.min, 
                        max: res.data.max,
                        step: res.data.step,
                        /*ranges: res.data.ranges,*/
                        range_map: res.data.range_map
                    };
                });
            }
            else{
                res = genTimeRanges();
                setData(prevState => {
                    return { ...prevState, 
                        min: res.data.min, 
                        max: res.data.max,
                        step: res.data.step,
                        /*ranges: res.data.ranges,*/
                        range_map: res.data.range_map
                    };
                });
            }
        }
        catch(err){
            res = err;
        }
        finally{
            if(res.error) throw res;
            else return res;
        }
    }, [min, max, genMinuteRanges, genTimeRanges])

    useEffect(() => {
        /* setup function that will receieve call from parent */
        dispatchevent && dispatchevent(childListener);

        genRanges();

        /* fix slider accepts 0 otherwise it show * Require as submit */
        _value != undefined && _value != null && onChange(_value);

        /* clean up code */
        return () => {
        }

    }, [dispatchevent, childListener, genRanges, onChange, _value]);

    const handleChange = (e, value) => {
        setValue(value);
        onChange(value);

        /* callbackevent && callbackevent(input.name, value); */
    }

    const handleChangeCommitted = (e, value) => {
        setValue(value);
        onChange(value);

        callbackevent && callbackevent(input.name, value);
    }

    const classes = useStyles();

    return (
        <FormControl 
            /*ref={refChild}*/
            required={required}
            /*error={isError}*/
            fullWidth={true}
            {...formControlProps}
        >
            <div className={classes.root}>
                <FormLabel id="slider-time-slot"
                    /* shrink like InputLable shrink */ 
                    style={{
                        transform: "translate(0, 1.5px) scale(0.75)",
                        transformOrigin: "top left"
                     }}>
                    {label}
                </FormLabel>
                <SliderTimeSlot
                    disabled={disabled}
                    getAriaValueText={valuetext}
                    aria-labelledby="slider-time-slot"
                    ValueLabelComponent={StyledValueLabel}
                    valueLabelFormat={(value)=> {
                        /* fix slider accepts 0 otherwise it show * Require as submit 
                         * original use condition
                         * input.value === undefined || input.value === null || input.value === "" 
                        */
                        if(_value === undefined || _value === null || _value === "")
                            return "?";
                        return data.range_map && data.range_map[value];
                    }}
                    value={_value}
                    min={data.min}
                    max={data.max}
                    step={data.step}
                    marks
                    valueLabelDisplay="on"
                    onChange={handleChange}
                    onChangeCommitted={handleChangeCommitted}
                    {...restInput}
                />
                <ErrorMessage
                    showError={isError}
                    meta={isMetaObj ? meta : field.meta} /* used field.meta but field.meta.error return object */
                    formHelperTextProps={formHelperTextProps}
                    helperText={isError ? error || submitError : helperText}
                />
            </div>
        </FormControl>
    );
}
