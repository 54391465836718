import React from 'react';
import get from 'lodash/get';
import PreviewImageField from './PreviewImageField';
import PreviewPDFField from './PreviewPDFField';

const PreviewField = props => {
    const {
        record,
        source,
        title,
        emptyText,
        onClick,
        ...rest
    } = props;
  
    const sourceValue = get(record, source); 

    if (Array.isArray(sourceValue)) {
    	return(
			sourceValue.map((item, index) => {
		        return 	  (item.file.ext.toLowerCase() == "pdf")
		        		? (<PreviewPDFField   record={item} source={source} title={title} onClick={onClick} />)
		        		: (<PreviewImageField record={item} source={source} title={title} onClick={onClick} />);  
			})
		);
	}
	else if(sourceValue){
		const fileInfo = get(record, 'file'); 
		return 	  (fileInfo.ext.toLowerCase() == "pdf")
				? (<PreviewPDFField   record={record} source={source} title={title} onClick={onClick} />)
				: (<PreviewImageField record={record} source={source} title={title} onClick={onClick} />);  

	}

	return (<PreviewImageField emptyText = {emptyText} />);
};

export default PreviewField;
