import { createMuiTheme } from '@material-ui/core/styles';
import { fade } from "@material-ui/core/styles/colorManipulator";
import indigo from '@material-ui/core/colors/indigo';
import pink from '@material-ui/core/colors/pink';
import red from '@material-ui/core/colors/red';

import config from 'config';

const Theme = createMuiTheme();

Theme.sidebar = {
    width: 127, // The default value is 240
    closedWidth: 0, // The default value is 55
};

Theme.palette = {
    primary: {
        main: config.theme.palette.primary.main,
    },
    type: 'dark',
    secondary: pink,
    error: red,
};

Theme.overrides = {
    MuiAppBar: {
        colorSecondary: {
            backgroundColor: Theme.palette.primary.main,
        }
    },
    RaToolbar: {
        toolbar: {
            backgroundColor: "transparent",
        }
    },
    MuiButton: { // override the styles of all instances of this component
        root: { // Name of the rule
            minWidth: 'unset', // Some CSS
        },
    },
    /* ref: https://codesandbox.io/s/j65nlor1v?file=/src/index.js:835-839 */
    MuiFilledInput: {
        root: {
            //border: "1px solid #e2e2e1",
            overflow: "hidden",
            borderRadius: 4,
            backgroundColor: "transparent", // "#fcfcfb"
            transition: Theme.transitions.create(["border-color"/*, "box-shadow"*/]),
            "&:hover": {
                backgroundColor: "transparent", // : "#fff"
            },
            "&$focused": {
                backgroundColor: "transparent", // : "#fff"
                // boxShadow: `${fade(Theme.palette.primary.main, 0.25)} 0 0 0 2px`,
                borderColor: Theme.palette.primary.main
            }
        }
    }
};

export default Theme;