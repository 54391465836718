import React, { Fragment, useState, useEffect }  from 'react';
import { useRefresh,
         email,
         List, Edit, Datagrid, 
         ReferenceArrayField, SingleFieldList, SelectArrayInput,
         TextField, ChipField,
         TextInput, SimpleForm,  
         SaveButton, EditButton, DeleteButton,
         SelectInput, Filter,
         regex
} from 'react-admin';

import { useQueryWithStore, Loading, Error } from 'react-admin';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core';

import UserCreateButton from './components/User/UserCreateButton';
import useAppData from './hooks/useAppData';

/* Remove the export action in an list view */
const UserActions = ({
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    permanentFilter,
    hasCreate,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total, ...props /* props.data will contain all records of current listing */
}) =>{
    const {data} = useAppData();
    const refresh = useRefresh();
    return(
        /* NOTE: If we use react-admin Toolbar will cause resource is null when pass to provider.
         * Then export function will be crashed
        */
        <Toolbar>    
           {data.activity && data.activity.user && data.activity.user.includes("create") && 
           <UserCreateButton 
                refresh={refresh}
           />}
           
        </Toolbar>
    );
};

/* Remove the <DeleteButton> from the toolbar in an edit view */
const UserEditToolbar = props => {
    return (
        <Toolbar {...props} >
            <SaveButton {...props} />
        </Toolbar>
    )
};

/* Replace default DeleteButton component to control show/hide */
const DeleteUserButton = (props) => {
    const {data} = useAppData();
    if( props.record && props.record.user_name != "app" &&
        data.activity && data.activity.user && data.activity.user.includes("delete"))
        return <DeleteButton {...props}/>;
    return null;
}

/* Replace default DeletButton component to control show/hide */
const EditUserButton = (props) => {
    const {data} = useAppData();
    if( props.record && props.record.user_name != "app" &&
        data.activity && data.activity.user && data.activity.user.includes("edit")
    ) 
        return <EditButton {...props}/>;
    return null;
}

const useFilterStyles = makeStyles({
    job_role: {
         minWidth: '160px',
    },
});

const UserFilter = props => {
    const classes = useFilterStyles();
    return (
        <Filter classes={classes} {...props}>
            <TextInput label="User name" source="user_name" alwaysOn />
        </Filter>
    );
}

export const UserList = props => {
    /*
    const {data} = useAppData();
    */

    const PermissionField = (props) => {
        return props.record && props.record.user_group && props.record.user_group.includes(4) 
            ? (<ReferenceArrayField 
                    label="Permission on" 
                    reference="Centre" 
                    source="activity_on" 
                    sortable={false} 
                    basePath={props.basePath}
                    record={props.record}
                >
                    <SingleFieldList>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>
            )
            : null;    
    }

    return (
        <Fragment>
        <List {...props}
            actions={<UserActions />}
            bulkActionButtons={false}
            filters={<UserFilter />} 
        >
            <Datagrid rowClick="edit">
                <TextField label="ID" source="id" />
                <TextField source="user_name" />
                <TextField source="first_name" />
                <TextField source="last_name" />
                <ReferenceArrayField label="Role" reference="Group" source="user_group" sortable={false}>
                    <SingleFieldList linkType={false} >
                        <ChipField source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>
                {/*
                <ReferenceArrayField label="Permission on" reference="Centre" source="activity_on" sortable={false}>
                    <SingleFieldList>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>
                */}
                <PermissionField />
                <EditUserButton />
                <DeleteUserButton />
            </Datagrid>
        </List>
        </Fragment>
    )
};

const validateEmail = email();
const validatePassword = regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/, 'The password must be between 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character');

/* access the current record in admin on Edit is impossible
 * except to create a custom Field component because it will
 * receive record as default. Or create a component (WithProps) to add this behaviour
*/
const WithProps = ({children,...props}) => children(props);

export const UserEdit = props => {
    const {data} = useAppData();
    const [showCentre, setShowCentre] = useState(false);
    const [intizalize, setInitialize] = useState(false);
    
    /* calls the Data Provider on mount and use cache 
     * issue: SelectInput not re-render, need to refresh then role show
    */
    let loaded, error, groups, centres;
    ({ loaded, error, data: groups } = useQueryWithStore({
        type: 'GET_LIST',
        resource: 'Group',
    }));

    /* get centre list */
    ({ loaded, error, data: centres } = useQueryWithStore({
        type: 'GET_LIST',
        resource: 'Centre',
    }));

    if (!loaded) { return <Loading />; }
    if (error) { return <Error />; }
    if(!groups) return null;

    /* use dataProvider and useEffect */
    /*
    const dataProvider = useDataProvider();
    const [group, setGroup] = useState();
    useEffect(() => {
        dataProvider.getList("Group", {})
        .then(({ data }) => {
            setGroup(data);
        })
        .catch(error => {
        })
        .finally(() => {})
    }, [])
    */

    /* find Signed user id */
    let current_group_id = null;
    const role_name = data.group[0];
    for (let i = 0, len = groups.length; i < len; i++) {
        if(groups[i].name == role_name){
            current_group_id = groups[i].id;
            break;
        }
    }

    const handleChangeRole = (value) => {
        setShowCentre((value === 4)) ;
	}

    /* need to transform user_group to array otherwise after saving will got error
     * ids.map is not function
    */
    const transform = data => ({
        ...data,
        user_group: [data.user_group]
    });

    return (
        <Edit {...props}
            transform={transform}
            title="Edit user">
            <WithProps>{({record,...props})=> {
                if(!intizalize){
                    setInitialize(true);
                    if(record.user_group && record.user_group.includes(4)) setShowCentre(true);
                }
                return(
                    <SimpleForm record={record} {...props} toolbar={<UserEditToolbar />}>
                        <TextInput source="user_name" />
                        <TextInput source="first_name" />
                        <TextInput source="last_name" />
                        {/*<TextInput source="email" validate={validateEmail} />*/}
                        {/*<PasswordInput source="password" validate={validatePassword} />*/}
                        <SelectInput 
                            label="Role"
                            source="user_group"
                            choices={groups}
                            onChange={e => handleChangeRole(e.target.value, record.user_group)}
                        />
                        {(showCentre) && <SelectArrayInput 
                            label="Select centre"
                            source="activity_on"
                            optionValue="uid"
                            choices={centres}
                        />}
                    </SimpleForm>)
                }
            }
            </WithProps>
        </Edit>
    );
}