import React, {Fragment} from 'react';

import { FormHelperText, FormHelperTextProps } from '@material-ui/core';
import { FieldMetaState, useField } from 'react-final-form';

/* equally to new syntax `export const name = (....) => {}` */
export function ErrorMessage({ showError, meta, formHelperTextProps, helperText }) {
	if (showError) {
		return <FormHelperText {...formHelperTextProps}>{meta.error || meta.submitError}</FormHelperText>;
	} else if (!!helperText) {
		return <FormHelperText {...formHelperTextProps}>{helperText}</FormHelperText>;
	} else {
		return <Fragment></Fragment>;
	}
}

const config = {
	subscription: {
		error: true,
		submitError: true,
		dirtySinceLastSubmit: true,
		touched: true,
		modified: true,
	},
};

export function useFieldForErrors(name) {
	return useField(name, config);
}

export function showError({ submitError, dirtySinceLastSubmit, error, touched, modified }) {
	return !!(((submitError && !dirtySinceLastSubmit) || error) && (touched || modified));
}