import React, { Fragment }  from 'react';

import Chip from '@material-ui/core/Chip';

const TextArrayField = ({ record, source }) => {
  /* resolve TypeError: record is undefined when click on delete
   * in case delete record
  */
  if(!record) return null;
  
  const array = record[source];
  if (typeof array === 'undefined' || array === null || array.length === 0) {
    return <div/>
  } else {
    return (
      <Fragment>
        {array.map(item => <Chip label={item} key={item}/>)}
      </Fragment>
    )    
  }
}
TextArrayField.defaultProps = { addLabel: true }

export default TextArrayField;