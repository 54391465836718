import React, { useEffect, useState, useRef, cloneElement } from 'react';
import { Link } from 'react-router-dom';
import { FormWithRedirect,
         List, Edit, Pagination, Datagrid, downloadCSV, 
         TextField, DateField, NumberField, ChipField,
         SingleFieldList,
         TextInput, SelectInput, SelectArrayInput, DateInput,
         NumberInput, ImageInput,
         Button, CreateButton, SaveButton, EditButton, DeleteButton, ExportButton,
         Filter, 
         useNotify} from 'react-admin';

import { useQueryWithStore, Loading, Error } from 'react-admin';

import jsonExport from 'jsonexport/dist';

import { Box } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import EditIcon from '@material-ui/icons/Edit';
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";

import { Worker } from '@react-pdf-viewer/core';

/* old button
import FollowUpButton from './components/Booking/FollowUpButton';
*/
import TextArrayField from './components/Fields/TextArrayField';
import StatusFieldStaff from './components/Fields/StatusFieldStaff';
import ImageField from './components/Fields/ImageField';
/*import PreviewImageField from './components/Fields/PreviewImageField';*/
import PreviewField from './components/Fields/PreviewField';
import CircularProgressWithLabel from './components/Progress/CircularProgressWithLabel';

import useAppData from './hooks/useAppData';
import uploadFile, {streamFile} from './utils/File';
import {humanFileSize} from './utils/string';

import get from 'lodash/get';

import config from 'config';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    /*
    headerCell:{
        '&:last-child': {
            maxWidth: 16,
        }
    },
    rowCell:{
        '&:last-child': {
            maxWidth: 16,
        }
    },
    */
    /*
    rowEven: {
        backgroundColor: '#ffffff',
    },
    rowOdd: {
        backgroundColor: '#d9e1f2',
    },
    */
});

/* set row background color based on deal_status */
const StaffRowStyle = (record, index) => {
    /*
    let backgroundColor = '#252425';
    if(record.status == 0) backgroundColor = "#6F6A6F";
    else if(record.status == 1) backgroundColor = "#f44a37"
    else if(record.status == 2) backgroundColor = "#5bd952";
    else if(index%2) backgroundColor = '#d9e1f2';
    return { backgroundColor: backgroundColor, color: 'red'};
    */
}

const FILTER_JOB_ROLE = config.staff.FILTER_JOB_ROLE;
const FILTER_STATUS = config.staff.FILTER_STATUS;

/* customize exporter */
const exporter = staffs => {
    const staffsForExport = staffs.map(staff => {
        /* ignore fields */
        const { id, bank_code, bank_name, bank_account_name, bank_account_no,...staffForExport } = staff;
        /* can add a field
        staffForExport.field = staff.field;
        */
        return staffForExport;
    });
    jsonExport(staffsForExport, {
        /* order fields in the export */
        headers: ["name", "ic", "employee_id", "hourly_rate", "job_role", "status", "centre_uid", "created_date_time"], 
        rename:["Name", "NRIC", "Employee ID", "Hourly Rate", "Job Roles", "Status", "Centre", "Created Time"]
    }, (err, csv) => {
        downloadCSV(csv, 'staffs'); // download as 'staffs.csv` file
    });
};

const StaffPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

/* customize actions in an list view */
const StaffActions = ({
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    permanentFilter,
    hasCreate,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
    /* NOTE: If we use react-admin Toolbar will cause resource is null when pass to provider.
     * Then export function will be crashed
    */
    <Toolbar>
        {filters && cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        {hasCreate && <CreateButton basePath={basePath} />}
        {exporter !== false && (
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filter={{ ...filterValues, ...permanentFilter }}
                exporter={exporter}
            />
        )}
        {/* Add your custom actions
        <UIButton color="primary" onClick={null}>Custom</UIButton>
        */}
    </Toolbar>
);

/* Remove the <DeleteButton> from the toolbar in an edit view */
const StaffEditToolbar = props => {
    return (
        <Toolbar {...props} >
            <SaveButton {...props} />
        </Toolbar>
    )
};

/* take a look at a component documentation and source code to know which classes are available for styling.
 * https://marmelab.com/react-admin/Theming.html
 * https://material-ui.com/customization/components/#overriding-styles-with-classes
*/
const useFilterStyles = makeStyles({
    job_role: {
         minWidth: '160px',
    },
});

const StaffFilter = props => {
    const classes = useFilterStyles();
    return (
        <Filter classes={classes} {...props}>
            <TextInput label="Name" source="name" alwaysOn />
            <TextInput label="NRIC" source="ic" alwaysOn />
            {/* support search one value only
            <SelectInput 
                label="Job role"
                source="job_role"
                choices={FILTER_JOB_ROLE}
                alwaysOn
            />
            */}
            <SelectArrayInput
                label="Job role"
                source="job_role"
                choices={FILTER_JOB_ROLE}
                options={{ fullWidth: true }}
                className={classes.job_role}
                alwaysOn
            />
            <SelectInput 
                label="Status"
                source="status"
                choices={FILTER_STATUS}
                /*allowEmpty emptyValue={"None"}*/
                alwaysOn
            />
            <DateInput 
                label="From"
                source="created_from" 
                alwaysOn
            />
            <DateInput
                label="To"
                source="created_to"
                alwaysOn
            />
        </Filter>
    );
}

/* Add new activity button to follow up deals */
const AddActivityButton = ({ record }) => {
    return(
        <Button
            component={Link}
            to={{
                pathname: `TimeSheet/create`,
                obj_data: record,
            }}
            title="Add Timesheet"
        >
            <ChatBubbleIcon />
        </Button>
    );
}

/* replace default EditButton component to add more params */
const EditStaffButton = ({ record }) => {
     /* resolve TypeError: record is undefined when click on delete
     * in case delete record
    */
   if(!record) return null;

    return(
        <Button
            component={Link}
            to={{
                pathname: `/staff/${record.id}`,
                /* pass centre_email to StaffEdit (location.centre_email)
                 * then save to local storage and use in httpClient later
                */
                /*centre_email: record.centre_email,*/
            }} 
            /*onClick={(e)=>{window.location.href = activityURL}}*/
            title="Edit"
        >
            <EditIcon />
        </Button>
    );
}

/* Replace default DeleteButton component to control show/hide */
const DeleteStaffButton = (props) => {
    const {data} = useAppData();
    if( props.record && props.record.user_name != "app" &&
        data.activity && data.activity.user && data.activity.user.includes("delete"))
        return <DeleteButton {...props}/>;
    return null;
}

export const StaffList = props => {
    const classes = useStyles();
    return (
        <List {...props} 
            exporter={exporter}
            pagination={<StaffPagination />}
            perPage={25}
            actions={<StaffActions />} 
            filters={<StaffFilter />} 
            sort={{ field: 'status', order: 'DESC' }}
            /*
            filterDefaultValues={{ 
                status: "none", 
                job_role: "none" 
            }}
            */
            title="Staffs" 
            bulkActionButtons={false} 
        >
            <Datagrid 
                optimized 
                rowStyle={StaffRowStyle}
                classes={{
                    headerCell: classes.headerCell,
                    rowCell: classes.rowCell
                    /*
                    rowEven: classes.rowEven,
                    rowOdd: classes.rowOdd
                    */
                }}
            >
                <TextField source="name" />
                <TextField source="ic" label="NRIC"/>
                <TextField source="employee_id" label="Employee id"/>
                <TextField source="phone"/>
                <TextField source="email"/>
                <DateField 
                    source="created_date_time"
                    label="Created time"
                    locales="sg-SG"
                    options={{ day: '2-digit', month: '2-digit', year: 'numeric', hour: "numeric", minute:"numeric" }} 
                    showTime={true} />
                <NumberField source="hourly_rate" locales="en-SG" options={{ style: 'currency', currency: 'SGD' }}/>
                <TextArrayField
                    source="job_role"
                    /* disable sorting for job_role because its json column */
                    sortable={false}
                >
                    <SingleFieldList>
                        <ChipField source="id" />
                    </SingleFieldList>
                </TextArrayField>
                <StatusFieldStaff source="status" />
                {/*<EditButton />*/}
                <EditStaffButton />
                {/*<AddActivityButton/>*/}
                <DeleteStaffButton />
            </Datagrid>
        </List>
    )
};

/* access the current record in admin on Edit is impossible
 * except to create a custom Field compoent because it will
 * receive record as default. Or create a component (WithProps) to add this behaviour
*/
const WithProps = ({children,...props}) => children(props);

export const StaffEdit = props => {
    /* NRIC */
    const [progress_nric, setNRICProgress] = useState(0);
    const acceptedNRICFiles = useRef(null);
    const deletedNRICFiles = useRef([]);
    /* Medical reports */
    const [progress_report, setReportProgress] = useState(0);
    const acceptedReportFiles = useRef(null);
    const deletedReportFiles = useRef([]);

    const notify = useNotify();
    const {data} = useAppData();

    const uploadNRICProgress = (uploadedChunks, nChunks, nUploadedSzie, nSize) => {
        setNRICProgress((uploadedChunks/nChunks)*100);
    }

    const uploadReportProgress = (uploadedChunks, nChunks, nUploadedSzie, nSize) => {
        setReportProgress((uploadedChunks/nChunks)*100);
    }

    const onAccepctedNRICFiles = async (files, event) => {
        try{
            setNRICProgress(0);
            
            if(event.target.name == config.app.upload_file.type.nric_pic){   
                for (const file of files)
                    file.category = config.app.upload_file.type.nric_pic;
                acceptedNRICFiles.current = files;
            }
        }
        catch(err){
            notify(`Could not upload: ${err}`, 'error');
        }
    }

    const onAccepctedReportFiles = async (files, event) => {
        try{
            setReportProgress(0);
           
            if(event.target.name == config.app.upload_file.type.report){   
                for (const file of files)
                    file.category = config.app.upload_file.type.report;
                acceptedReportFiles.current = files;
            }
        }
        catch(err){
            notify(`Could not upload: ${err}`, 'error');
        }
    }

    const onRejectedNRICFiles = async (fileRejections, event) => {
        let szFile = "File",
            szVerb = "is";
        if(fileRejections.length > 1){
            szFile = "Files";
            szVerb = "are";
        }
        notify(`${szFile} ${fileRejections.map(e=>e.name).join(",")} ${szVerb} rejected due to file size limit or invalid format`, 'error');   
    }

    const onRejectedReportFiles = async (fileRejections, event) => {
        let szFile = "File",
            szVerb = "is";
        if(fileRejections.length > 1){
            szFile = "Files";
            szVerb = "are";
        }
        notify(`${szFile} ${fileRejections.map(e=>e.name).join(",")} ${szVerb} rejected due to file size limit or invalid format`, 'error');   
    }

    const handleSubmit = async (handleSubmitWithRedirect, record)=> {
        let fileInfo = null,
            error = false;
        try{
            if(acceptedNRICFiles.current){
                for (const file of acceptedNRICFiles.current){
                    fileInfo = await uploadFile(
                        file, data.token,
                        `${config.app.back_end.url}/File/upload`,
                        `${config.app.back_end.url}/File/merge`,
                        uploadNRICProgress
                    );
                }
            }

            if(acceptedReportFiles.current){
                for (const file of acceptedReportFiles.current){
                    fileInfo = await uploadFile(
                        file, data.token,
                        `${config.app.back_end.url}/File/upload`,
                        `${config.app.back_end.url}/File/merge`,
                        uploadReportProgress
                    );
                }
            }
        }
        catch(err){
            error = true;
            notify(`Could not upload: ${err}`, 'error');
        }
        finally{
            if(!error)
                handleSubmitWithRedirect();
        }
    }

    /* transform call after handleSubmit */
    const transform = data => {
        /* TRANSFORM UPLOAD FILES */
        /* data[nric_field].rawFile is File object has the following props
         *  lastModified: 1604826577465
         *  name: "Large-Sample-Image-download-for-Testing.jpg"
         *  path: "Large-Sample-Image-download-for-Testing.jpg"
         *  size: 15483160
         *  type: "image/jpeg"
         * 
         *  category: wil be added during transformation.
         *  hash: prop is added in File util.
        */
        
        const nric_field = config.app.upload_file.type.nric_pic,
              report_field = config.app.upload_file.type.report;
        let   arrayFile = [];

        if(data[nric_field]){
            /* multiple or single in ImageInput */
            arrayFile = Array.isArray(data[nric_field]) 
                        ? arrayFile.concat(data[nric_field])
                        : arrayFile.concat([data[nric_field]]);
            data[nric_field] = [];
        }
        if(data[report_field]){        
            /* multiple or single in ImageInput */
            arrayFile = Array.isArray(data[report_field])
                        ? arrayFile.concat(data[report_field])
                        : arrayFile.concat([data[report_field]]);
            data[report_field] = [];
        }

        /* build data to submit to back-end in array format */
        for(let file of arrayFile){
            file = file.rawFile;                    
            let i = file.name.lastIndexOf('.'),
                ext = (i < 0) ? null : file.name.substr(i+1);

            let clonedFileObj = {
                hash: file.hash,
                name: file.name,
                ext: ext,
                path: file.path,
                size: file.size,
                type: file.type,
                category: file.category,
                last_modified: file.lastModified,
                user_id: data.id
            }
            if(file.category == nric_field) data[nric_field].push(clonedFileObj);
            else if(file.category == report_field) data[report_field].push(clonedFileObj);
        }

        /* TRANSFORM DELETE FILES */
        if(deletedNRICFiles.current.length){
            data[nric_field] = data[nric_field] 
                            ? data[nric_field].concat(deletedNRICFiles.current)
                            : deletedNRICFiles.current;
        }
        if(deletedReportFiles.current.length){
            data[report_field] = data[report_field]
                            ? data[report_field].concat(deletedReportFiles.current)
                            : deletedReportFiles.current;
        }
        return data;
    }

    const StaffEditForm = props => {
        const [nric, setNRIC] = useState(null);
        const [report, setReport] = useState(null);
        /* get users file using import useQueryWithStore will be called on mount */
        /*
        const { loaded, error, data: files } = useQueryWithStore({
            type: 'getOne',
            resource: 'File',
            payload: { id: props.record.id }
        });

        if (!loaded) { return <Loading />; }
        if (error) { return <Error />; }
        */

        /* transform call after handleSubmit */
        const transform = (data) => {
            /* back-end need to check permssion bases on path_uid */
            data.path_uid = data.centre_uid;
            return data;
        }

        /* Build staff's file for previewing and update
         * single image format
         * read code: https://github.com/marmelab/react-admin/blob/master/packages/ra-ui-materialui/src/field/ImageField.tsx
         * Single image
         *  let record = {
         *      src  : url
         *      title: display title
         *      file : record returns from getOne 
         *   };
         * Multitple images
         * let record = {
         *   src: [
         *      { src  : url
         *        title: display title
         *        file : record returns from getOne
         *      },
         *      { src  : url
         *         title: display title
         *         file : record returns from getOne
         *      },
         *   ]
         * };
        */
        useEffect(() => {
            let nricImageArray = [],
                reportArray = [];
            if(props.record && props.record.files){ 
                for (const file of props.record.files){
                    if(file.category == config.app.upload_file.type.nric_pic &&
                       !file.delete){
                        nricImageArray.push({
                            /* src and tile will be used in CustomPrviewField */
                            src:`${config.app.back_end.url}/File/stream/${file.hash}?token=${data.token}`,
                            title: file.name,
                            /* file is file info pass to onclick in CustomPrviewField */
                            file: file,
                        })
                    }
                    else if(file.category == config.app.upload_file.type.report &&
                       !file.delete){
                        reportArray.push({
                            /* src and tile will be used in CustomPrviewField */
                            src:`${config.app.back_end.url}/File/stream/${file.hash}?token=${data.token}`,
                            title: file.name,
                            /* file is file info pass to onclick in CustomPrviewField */
                            file: file,
                        })
                    }
                }
            }
            if(nricImageArray.length) setNRIC(nricImageArray);
            if(reportArray.length) setReport(reportArray);
        }, [props.record.files]);

        const onDeleteNRICFile = (event, file) => {
            // setNRIC(prevState => prevState.filter((f) => f.file.hash !== file.hash));
            let nricImageArray =[];
            for (let i = 0, len = nric.length; i < len; i++){
                if( nric[i].file.hash != file.hash)
                    nricImageArray.push(nric[i]);
                else{ /* prepare deleted file list to call update staff API to delete */
                    file.delete = true;
                    deletedNRICFiles.current.push(file);
                }
            }
            
            setNRIC(nricImageArray);
        }

         const onDeleteReportFile = (event, file) => {
            // setNRIC(prevState => prevState.filter((f) => f.file.hash !== file.hash));
            let reportArray =[];
            for (let i = 0, len = report.length; i < len; i++){
                if( report[i].file.hash != file.hash)
                    reportArray.push(report[i]);
                else{ /* prepare deleted file list to call update staff API to delete */
                    file.delete = true;
                    deletedReportFiles.current.push(file);
                }
            }
            
            setReport(reportArray);
        }

        return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
        <FormWithRedirect
            {...props}
            render={formProps => (
                // here starts the custom form layout
                <form>
                    <Box p="1em" width="50%">
                        <Box display="flex">
                            <Box flex={1} mr="0.5em">
                                <TextInput source="name" fullWidth/>
                            </Box>
                            <Box flex={1} ml="0.5em">
                                <TextInput source="ic" label="NRIC" fullWidth/>
                            </Box>
                        </Box>
                        <Box display="flex">
                            <Box flex={1} mr="0.5em">
                                <NumberInput source="hourly_rate" fullWidth />
                            </Box>
                            <Box flex={1} ml="0.5em">
                                <SelectArrayInput
                                    source="job_role"
                                    label="Job role"
                                    choices={FILTER_JOB_ROLE}
                                    fullWidth
                                />
                            </Box>
                        </Box>
                        <Box display="flex">
                            <Box flex={1} mr="0.5em">
                                <TextInput source="phone" fullWidth/>
                            </Box>
                            <Box flex={1} ml="0.5em">
                                <TextInput source="email" fullWidth/>
                            </Box>
                        </Box>
                        {/*
                        <Box display="flex">
                            <Box flex={1} mr="0.5em">
                                <TextInput source="bank_name" fullWidth />
                            </Box>
                            <Box flex={1} mr="0.5em">
                                <TextInput source="bank_code" fullWidth />
                            </Box>
                        </Box>
                        <Box display="flex">
                            <Box flex={1} mr="0.5em">
                                <TextInput source="bank_account_name" fullWidth />
                            </Box>
                            <Box flex={1} mr="0.5em">
                                <TextInput source="bank_account_no" fullWidth />
                            </Box>
                        </Box>
                        */}
                         <Box display="flex">
                            <Box flex={1} mr="0.5em">
                                <TextInput 
                                    fullWidth
                                    label="Employee id"
                                    source="employee_id"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                />
                            </Box>
                            <Box flex={1} mr="0.5em">
                                <TextInput 
                                    fullWidth
                                    type="date"
                                    source="medical_report_date"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                />
                            </Box>
                            <Box flex={1} mr="0.5em">
                                <SelectInput 
                                    fullWidth
                                    label="Status"
                                    source="status"
                                    choices={config.status.staff.select}
                                />
                            </Box>
                        </Box>

                        {/* NRIC PHOTOS PART */}
                        <Box display="flex">
                            <Box flex={progress_nric > 0 ? 11 : 12} mr="0.5em">
                                <ImageInput
                                    multiple
                                    source={config.app.upload_file.type.nric_pic}
                                    label="NRIC photo (jpg, png, pdf)"
                                    accept="image/png, image/jpg, image/jpeg, application/pdf"
                                    maxSize={config.app.upload_file.max_size}
                                    placeholder={`File size should not exceed ${humanFileSize(config.app.upload_file.max_size)}`}
                                    options={
                                        {   onDropAccepted:onAccepctedNRICFiles,
                                            onDropRejected: onRejectedNRICFiles,
                                        }
                                    }
                                    >
                                    <ImageField source="src" title="images"/>
                                </ImageInput>
                            </Box>
                            {(progress_nric > 0) && (<Box flex={1} ml="0.5em" style={{marginTop: "28px"}}>
                                <CircularProgressWithLabel value={progress_nric} />
                            </Box>)}
                        </Box>
                        <Box flex={1}>
                            {/*
                            <PreviewImageField 
                                record={{src: nric}}
                                source="src"
                                src="src"
                                title="title"
                                onClick={onDeleteNRICFile}
                            />
                            */}
                            <PreviewField
                                record={{src: nric}}
                                source="src"
                                src="src"
                                title="title"
                                /*emptyText="Cannot preiew file"*/
                                onClick={onDeleteNRICFile}
                            />
                        </Box>

                        {/* MEDICAL REPORTS PART*/}
                        <Box display="flex">
                            <Box flex={progress_report > 0 ? 11 : 12} mr="0.5em">
                                <ImageInput
                                    multiple
                                    source={config.app.upload_file.type.report}
                                    label="Medical report (jpg, png, pdf)"
                                    accept="image/png, image/jpg, image/jpeg, application/pdf"
                                    maxSize={config.app.upload_file.max_size}
                                    placeholder={`File size should not exceed ${humanFileSize(config.app.upload_file.max_size)}`}
                                    options={
                                        {   onDropAccepted:onAccepctedReportFiles,
                                            onDropRejected: onRejectedReportFiles,
                                        }
                                    }
                                    >
                                    <ImageField source="src" title="images"/>
                                </ImageInput>
                            </Box>
                            {(progress_report > 0) && (<Box flex={1} ml="0.5em" style={{marginTop: "28px"}}>
                                <CircularProgressWithLabel value={progress_report} />
                            </Box>)}
                        </Box>
                        <Box flex={1}>
                            <PreviewField
                                record={{src: report}}
                                source="src"
                                src="src"
                                title="title"
                                /*emptyText="Cannot preiew file"*/
                                onClick={onDeleteReportFile}
                            />
                        </Box>

                    </Box>
                    <Toolbar>
                        <Box display="flex" justifyContent="space-between" width="100%">
                            <SaveButton
                                saving={formProps.saving}
                                handleSubmitWithRedirect={
                                    /*formProps.handleSubmitWithRedirect*/
                                    () => handleSubmit(formProps.handleSubmitWithRedirect, formProps.record)
                                }
                                transform={transform}
                            />
                            {/*<DeleteButton record={formProps.record} />*/}
                        </Box>
                    </Toolbar>
                </form>
            )}
        />
        </Worker>
        );
    }

    return (
        /*
        <Edit {...props} 
            title="Edit staff">
            <WithProps>{({record,...props})=>
            <SimpleForm record={record} {...props} toolbar={<StaffEditToolbar />}>
                <Box display="flex">
                    <Box flex={1} mr="0.5em">
                        <TextInput source="name"/>
                    </Box>
                    <Box flex={1} ml="0.5em">
                        <TextInput source="ic" label="NRIC"/>
                    </Box>
                </Box>
                <NumberInput source="hourly_rate" />
                <SelectArrayInput
                    source="job_role"
                    label="Job role"
                    choices={FILTER_JOB_ROLE}
                />
                <ImageInput
                    source={config.app.upload_file.type.nric_pic}
                    label="Images"
                    accept="image/png, image/jpg, image/jpeg"
                    maxSize={5000000}
                    placeholder={
                      <p>
                        Upload Image
                        <span >
                          *File size should not exceed 5MB
                        </span>
                      </p>
                    }
                    >
                    <ImageField source="src" title="images" />
                </ImageInput>
            </SimpleForm>
            }
            </WithProps>
        </Edit>
        */
        <Edit {...props} undoable={false}
        >
            <StaffEditForm />
        </Edit>
    );
}